import Axios from "axios";

const INITIAL_STATE = {
  downloads: {
    id: "",
    name: "",
    uri: "",
    files: [
      {
        id: null,
        name: null,
        file_path: null,
      },
    ],
  },
};

export const updateDownloads = param => ({
  type: "SET_DOWNLOADS",
  payload: param,
});

export const getDownloads = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateDownloads(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_DOWNLOADS":
      return { ...state, downloads: action.payload };
    default:
      return state;
  }
};
