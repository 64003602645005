import React from "react";

export default function Forgot({ width = "20.706", height = "22.346", fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 24.706 26.346">
      <g transform="translate(-15.937)">
        <g transform="translate(15.937)">
          <g transform="translate(0)">
            <ellipse cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(13 17)" fill={fillColor} />
            <path
              d="M32.867,24.288H20.054A2.061,2.061,0,0,1,18,22.23V13.791a2.061,2.061,0,0,1,2.058-2.058h14.82a2.1,2.1,0,0,1,.568.063,1.029,1.029,0,0,0,.567-1.979,4.09,4.09,0,0,0-1.135-.143H33.637V6.045a6.176,6.176,0,0,0-12.35,0v3.63H20.054a4.121,4.121,0,0,0-4.117,4.117V22.23a4.121,4.121,0,0,0,4.117,4.117H32.867a1.029,1.029,0,0,0,0-2.058ZM23.345,6.045a4.119,4.119,0,0,1,8.233,0v3.63H23.345Z"
              transform="translate(-15.937)"
              fill={fillColor}
            />
            <circle cx="1" cy="1" r="1" transform="translate(9 17)" fill={fillColor} />
            <path
              d="M361.309,264.613a3.655,3.655,0,0,0-7.309.042,1.029,1.029,0,1,0,2.058,0,1.6,1.6,0,0,1,3.193,0c0,.009,0,.019,0,.028a1.6,1.6,0,0,1-.971,1.443,2.737,2.737,0,0,0-1.655,2.527v.89a1.029,1.029,0,0,0,2.058,0v-.89a.682.682,0,0,1,.405-.634,3.651,3.651,0,0,0,2.221-3.364C361.31,264.641,361.31,264.627,361.309,264.613Z"
              transform="translate(-336.604 -247.569)"
              fill={fillColor}
            />
            <circle cx="1" cy="1" r="1" transform="translate(20 24)" fill={fillColor} />
            <circle cx="1" cy="1" r="1" transform="translate(5 17)" fill={fillColor} />
          </g>
        </g>
      </g>
    </svg>
  );
}
