export default {
  title: "light",
  colors: {
    primary: "#d9473d",
    secundary: "#861811",
    tertiary: "#6f130d",

    backgroundPrimary: "#ffffff",
    backgroundSecundary: "#f8f8f8",
    backgroundTertiary: "#e6e6e6",

    text: "#3c3535",
    title: "#861811",

    boxPrimary: "#d9473d",
    boxSecundary: "#861811",

    icons: "#3C3535",

    search: "rgba(134,24,17,0.06)",

    newsletter: "#d9473d",
  },
  sizes: {
    big: {
      title: "big",
      size: +1,
    },
    small: {
      title: "small",
      size: -1,
    },
  },
};
