import React from "react";

import { FaWhatsapp } from "react-icons/fa";

const WhatsButton = ({ message, phone }) => {
  return (
    <a
      className="whastapp"
      href={`https://api.whatsapp.com/send?phone=55${phone.replace(/\D/g, "")}&text=${message}`}
      target="_blank"
      rel="noopener noreferrer">
      <FaWhatsapp size={25} />
      <span>&nbsp;Fale pelo WhatsApp</span>
    </a>
  );
};

export default WhatsButton;
