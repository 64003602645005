export const USER_NAME = "USER_SITE_USER_NAME";
export const USER_ID = "USER_SITE_USER_ID";
export const USER_EMAIL = "USER_SITE_USER_EMAIL";

export const isAuthenticated = () => (localStorage ? localStorage.getItem(USER_ID) !== null : null);
export const getToken = () => (localStorage ? localStorage.getItem(USER_ID) : null);
export const getName = () => (localStorage ? localStorage.getItem(USER_NAME) : null);
export const getId = () => (localStorage ? localStorage.getItem(USER_ID) : null);

export const login = (id, email, name) => {
  localStorage.setItem(USER_NAME, name);
  localStorage.setItem(USER_ID, id);
  localStorage.setItem(USER_EMAIL, email);
};

export const logout = () => {
  localStorage.removeItem(USER_NAME);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(USER_EMAIL);
};
