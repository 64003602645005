import React, { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { Figure, Image, Container } from "./styles";

import api from "./../../services/api";

function Popup() {
  const [open, setOpen] = useState(false);

  const [uri, setUri] = useState("");
  const [target, setTarget] = useState("");
  const [title, setTitle] = useState("");
  const [image_path, setImage_path] = useState("");

  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function loadPopup() {
      try {
        const { data } = await api.get(`/popups/site`, {});

        if (data) {
          setUri(data.uri);
          setTarget(data.target);
          setTitle(data.title);
          setImage_path(data.image_path);

          setOpen(true);
        }
      } catch (error) {
        setOpen(false);
      }
    }

    loadPopup();
  }, []);

  return (
    <Container className="teste">
      <Modal open={open} onClose={onCloseModal} center blockScroll={false} styles={{ overlay: { padding: "10px" }, modal: { padding: "10px" } }}>
        <Figure>
          {uri ? (
            <>
              {uri.substr(0, 4) === "http" ? (
                <a href={uri} title={title} alt={title} target={target} rel="noopener noreferrer">
                  <Image src={image_path} alt={title} />
                </a>
              ) : (
                <Link to={uri} title={title} alt={title} target={target}>
                  <Image src={image_path} alt={title} />
                </Link>
              )}
            </>
          ) : (
            <>
              <Image src={image_path} alt={title} />
            </>
          )}
        </Figure>
      </Modal>
    </Container>
  );
}

export default memo(Popup);
