import React from "react";

export default function Color(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 536.022 78.431">
      <g transform="translate(-141.91 -260.388)">
        <g transform="translate(141.91 260.388)">
          <path
            d="M351.63,291.521V264.182c0-1.237.307-1.573,1.563-1.582,1.458-.01,2.925,0,4.382,0,2.215,0,2.551.269,3.414,2.3,1.458,3.414,2.992,6.8,4.478,10.2,1.314,3.021,2.579,6.06,3.9,9.071s2.723,6,4.056,9.014,2.608,6.041,3.932,9.062q1.654,3.783,3.366,7.556c.1.221.393.527.537.5a1.036,1.036,0,0,0,.642-.527c1.17-2.618,2.3-5.264,3.462-7.892.959-2.167,1.947-4.325,2.915-6.492.93-2.1,1.851-4.21,2.781-6.31.988-2.244,1.975-4.478,2.963-6.722.969-2.206,1.927-4.421,2.9-6.626.786-1.793,1.553-3.586,2.349-5.36.988-2.206,2-4.392,2.992-6.588a1.8,1.8,0,0,1,1.9-1.179c1.611.058,3.232,0,4.843.019,1.419.01,1.688.3,1.688,1.688q-.014,26.135-.019,52.261c0,.844-.01,1.7.029,2.541.038,1.007-.393,1.515-1.419,1.506-1-.01-2,0-3-.01A1.15,1.15,0,0,1,405,319.32c0-.882.019-1.764.019-2.656q0-20.425.01-40.84c0-1.227.1-2.464.058-3.692-.019-.384-.364-.758-.556-1.132a4.981,4.981,0,0,0-.71.93c-1.016,2.311-1.995,4.641-2.992,6.962-.949,2.215-1.9,4.44-2.858,6.645-.9,2.071-1.832,4.133-2.742,6.195-1,2.282-1.985,4.564-2.992,6.837-.959,2.167-1.966,4.325-2.925,6.492-.93,2.1-1.841,4.21-2.752,6.319-1.218,2.81-2.388,5.648-3.711,8.4a2.208,2.208,0,0,1-1.736.758,1.9,1.9,0,0,1-1.438-.853c-1.688-3.644-3.26-7.345-4.9-11.018-1.736-3.884-3.538-7.729-5.274-11.612-1.716-3.845-3.366-7.719-5.082-11.565-1.342-3.011-2.762-5.984-4.1-8.985-.575-1.295-1.036-2.637-1.553-3.951a2.2,2.2,0,0,0-.393-.825,4.066,4.066,0,0,0-.969-.518,3.207,3.207,0,0,0-.316.988c-.01.806.105,1.611.105,2.416q.029,22.208.048,44.417c0,1.036-.47,1.505-1.419,1.553-.921.048-1.841.019-2.771.029-1.026.01-1.429-.5-1.419-1.505.029-5.111.029-10.232.029-15.343V291.54C351.64,291.521,351.64,291.521,351.63,291.521Z"
            transform="translate(-150.527 -260.479)"
            fill="#7f171a"
          />
          <path
            d="M441.408,294.167q0,12.456.01,24.913c0,.949-.364,1.477-1.314,1.505s-1.927.038-2.886-.01-1.419-.547-1.41-1.573q.072-24.74.115-49.48c0-1.668-.336-1.995-2.033-1.995q-8.127.014-16.263.019c-1.755,0-1.918-.192-1.918-1.908-.019-3.117-.278-3.059,3.078-3.059q20.123.029,40.255.01c.384,0,.767.01,1.151.019,1.055.01,1.553.518,1.458,1.592-.038.384-.019.767-.019,1.151.038,2.052-.1,2.2-2.205,2.2q-7.954,0-15.918-.01c-1.947,0-2.186.259-2.186,2.177q.014,12.226.01,24.452Z"
            transform="translate(-153.159 -260.478)"
            fill="#7f171a"
          />
          <path
            d="M273.674,315.326c-12.044,0-13.818-3.8-8.832-27.089,5.888-27.521,23.177-25.8,23.177-10.74,0,10.625-5.552,37.829-14.345,37.829Zm28.758-43.947c9.014,15.247-1.486,41.233-22.314,47.783-16.839,5.3-41.089,1.659-41.646-20.837-.211-8.659,3.855-19.907,9.11-23.426v7.825h10.433v-3.912h-5.332c.412-8.5-6.06-10.989-12.974-7.671a14.559,14.559,0,0,0-4.862,4.056c-2.052,2.426-5.293,6.434-6.281,11.938l-5.763,32.1a49.509,49.509,0,0,0,33.485,17.126c29.937,2.838,57.027-24.241,57.822-60.162h10.433c0,34.885-29.333,61.332-63.48,62.617h-5.389a69.879,69.879,0,0,1-51.014-26.332l7.691-44.11c-10.529.873-9.666,4.133-11.737,13.041h-6.521c0-7.46-.125-14.614-10.462-14.681a16.841,16.841,0,0,0-14.595,9.2c-1.736,3.3-13.07,32.967-.115,37.206,1.045.3,2.09.6,3.126.9,9.33,1.333,14.767-4.258,16.819-13.06H199.3c-3.836,7.249-6.051,12.2-13.54,16.465-6.617,3.768-15.688,4.229-23.052,3.26-12.264-1.611-19.466-8.064-20.8-18.517,0-12.945,1.39-22.055,11.373-31.941,14.4-14.259,38.069-8.323,51.225-8.391,9.57-.048,19.14-.01,28.71-.01,0,1.745-.412,6.434-.412,8.18,0,.431,12.082-15.544,32.5-7.038,12.725-5.226,30.609-2.647,37.129,8.39Z"
            transform="translate(-141.91 -260.388)"
            fill="#861811"
            fill-rule="evenodd"
          />
        </g>
        <text transform="translate(466.932 275.667)" fill="#585857" font-size="19" font-family="Helvetica">
          <tspan x="0" y="0">
            CONSELHO REGIONAL
          </tspan>
        </text>
        <text transform="translate(466.932 298.031)" fill="#585857" font-size="18" font-family="Helvetica">
          <tspan x="0" y="0">
            DE ODONTOLOGIA
          </tspan>
        </text>
        <text transform="translate(466.932 321.496)" fill="#585857" font-size="19" font-family="Helvetica">
          <tspan x="0" y="0">
            DE MATO GROSSO
          </tspan>
        </text>
      </g>
    </svg>
  );
}
