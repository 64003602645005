import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import "./styles.css";

export default function Share() {
  return (
    <div className="share-button">
      <FacebookShareButton url={global.window ? global.window.location : ""}>
        <FacebookIcon size={30} round={true} />
      </FacebookShareButton>
      <TwitterShareButton url={global.window ? global.window.location : ""}>
        <TwitterIcon size={30} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={global.window ? global.window.location : ""}>
        <WhatsappIcon size={30} round={true} />
      </WhatsappShareButton>
      <EmailShareButton url={global.window ? global.window.location : ""}>
        <EmailIcon size={30} round={true} />
      </EmailShareButton>
    </div>
  );
}
