import styled from "styled-components";
import { Link } from "react-router-dom";

export const Main = styled.main`
  position: relative;
  margin-top: 170px;
  @media (max-width: 1366px) {
    margin-top: 152px;
  }
  @media (max-width: 1281px) {
    margin-top: 230px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 209px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    margin-top: 200px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    margin-top: 110px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Container = styled.section`
  padding: 40px 140px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  @media (max-width: 1366px) {
    padding: 40px 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
`;

export const Aside = styled.aside`
  align-self: flex-start;
  width: 408px;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    width: 90%;
    align-self: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const FilterLabel = styled.label`
  color: #3c3535;
  font-size: 20px;
  line-height: 27px;
  font-family: "NunitoSans-Bold";
  margin-bottom: 20px;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 8px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const FilterInput = styled.input`
  color: #3c3535;
  font-size: 20px;
  line-height: 27px;
  font-family: "NunitoSans-Light";
  border: 0;
  border-bottom: 1px solid rgba(60, 53, 53, 0.42);
  height: 30px;
`;

export const FilterSelect = styled.select`
  color: #3c3535;
  font-size: 20px;
  line-height: 27px;
  font-family: "NunitoSans-Light";
  border: 0;
  border-bottom: 1px solid rgba(60, 53, 53, 0.42);
  height: 50px;
  background: white;
  text-transform: uppercase;
`;

export const FilterButton = styled.button`
  font-size: 21px;
  letter-spacing: 2px;
  line-height: 28px;
  font-family: "NunitoSans-Regular";
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  border-radius: 5px;
  background-color: #861811;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 13px 43px;
  margin-top: 60px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    border-radius: 20px;
    opacity: 0.7;
  }
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 23px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxTitle = styled.div`
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3c3535;
  font-family: "NunitoSans-ExtraBold";
  font-size: 19px;
  line-height: 35px;
  text-transform: uppercase;
  padding: 22px;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 30px;
    padding: 2px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    padding: 13px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Box = styled.div`
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 50px;
`;

export const ContentMain = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentBody = styled.div`
  flex: 1;
  width: 100%;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pagination .pageItem {
    padding: 10px 18px;
    margin: 5px;
    color: #3c3535;
    font-family: "NunitoSans-SemiBold";
    font-size: 22px;
    line-height: 30px;
    background-color: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  }
  .pagination li.disabled {
    opacity: 0.5;
  }
  .pagination li.disabled a {
    cursor: default;
  }
  .pagination li.active {
    background: #3c3535;
    color: #fff;
    margin: 5px;
  }
  @media (max-width: 1366px) {
    margin-left: 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-left: 0px;
    width: 100%;
    .pagination .pageItem {
      padding: 3px 10px;
      margin: 5px;
      color: #3c3535;
      font-family: "NunitoSans-SemiBold";
      font-size: 16px;
      line-height: 27px;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ListItem = styled.div`
  background-color: #ffffff;
  min-height: 225px;
  max-height: 225px;
  flex: 1;
  margin-bottom: 35px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: auto;
    max-height: inherit;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ListItemFigure = styled.figure`
  /*min-width: 290px;
  max-width: 290px;*/
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3b3535;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    /*min-width: 220px;
    max-width: 220px;
    height: 182px;*/
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    /*min-width: 100%;
    max-width: 100%;
    height: auto;*
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ListItemImage = styled.img`
  width: 300px;
  height: auto;
  transition: 0.4s;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    /*height: auto;*/
    transition: 0.4s;
    /* width: 100%;*/
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ListItemLink = styled(Link)`
  font-family: "NunitoSans-Regular";
  font-size: 18px;
  line-height: 30px;
  color: #861811;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  margin-top: 15px;
  &::after {
    content: " ";
    height: 1px;
    width: 0px;
    background-color: #861811;
    transition: 0.4s ease-in-out;
    margin-top: -4px;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 28px;
    margin-top: 0;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    padding: 15px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
  &:hover {
    ${ListItemLink}::after {
      width: 100%;
    }
  }
`;

export const ListItemTitle = styled.h3`
  font-family: "NunitoSans-SemiBold";
  font-size: 18px;
  line-height: 32px;
  color: #3c3535;
  margin-bottom: 5px;
  @media (max-width: 1366px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    margin-bottom: 5px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ListItemSubTitle = styled.label`
  font-family: "NunitoSans-Light";
  font-size: 14px;
  line-height: 14px;
  color: #3c3535;
  margin-bottom: 15px;
`;

export const ListItemSummary = styled.p`
  font-family: "NunitoSans-Regular";
  font-size: 16px;
  line-height: 24px;
  color: #3c3535;
  overflow: hidden;
  flex: 1;
  margin-bottom: 0px;
  max-height: 75px;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    display: none;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
