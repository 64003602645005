import styled from "styled-components";

export const Header = styled.header`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const H1 = styled.h1`
  font-family: "NunitoSans-Bold";
  font-size: 30px;
  line-height: 45px;
  color: ${props => props.theme.colors.title};
  text-transform: ${props => (props.uppercase ? "uppercase" : "")};
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 70px;

  &::after {
    content: "";
    width: 57%;
    height: 4px;
    background-color: ${props => props.theme.colors.title};
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 22px;
    line-height: 37px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
