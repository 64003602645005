import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import api from "./../../../services/api";

import "./styles.css";
import { Container } from "./styles.js";

import { getDateByTimeZoneCba } from "./../../../services/functions";
import addDays from "date-fns/addDays";
import bg from "./../../../assets/bg-certidao.png";

function Print() {
  let { configReducers: config } = useSelector(state => state);
  config = config.config;

  const { key, cpf } = useParams();
  const history = useHistory();

  const [certificate, setCertificate] = useState({});

  useEffect(() => {
    async function loadCertificate() {
      try {
        const { data } = await api.get(`/courses/check/certificate/?key=${key}&cpf=${cpf}`);
        setCertificate(data);
      } catch (err) {
        alert("Não foi possivel emitir o certificado.\nCertificado não existe.");
        history.goBack();
      }
    }

    if (!key) {
      alert("Erro ao processar certificado.");
      history.goBack();
    }

    loadCertificate();
  }, []);

  useEffect(() => {
    if (typeof global.window !== "undefined") {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, []);

  return (
    <Container>
      <div className="certificate">
        <img src={certificate?.course?.certificate_path} />
        <div className="content">
          Certificamos que o(a) <strong>{certificate?.user?.name}</strong>, inscrito(a) sob CPF nº <strong>{certificate?.user?.cpf}</strong> participou do curso{" "}
          <strong className="courseName">{certificate?.course?.name}</strong>, mistrado(a) pelo(a) professor(a){" "}
          <strong>{certificate?.course?.instructors}</strong>, realizado no dia{" "}
          <strong>{getDateByTimeZoneCba(certificate?.course?.start_date, "dd'/'MM'/'yyyy")}</strong>, com a carga horária de{" "}
          <strong>{certificate?.course?.total_hours}</strong> hora(s), das <strong>{getDateByTimeZoneCba(certificate?.course?.start_date, "HH':'mm")}</strong>{" "}
          às <strong>{getDateByTimeZoneCba(certificate?.course?.end_date, "HH':'mm")}</strong> horas realizado no(a){" "}
          <strong>{certificate?.course?.local}</strong>.
        </div>
      </div>
    </Container>
  );
}

export default Print;
