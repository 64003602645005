import Axios from "axios";
import { useHistory } from "react-router-dom";

const INITIAL_STATE = {
  newsDetails: {},
};

export const updateNewsDetails = param => ({
  type: "SET_NEWS_DETAILS",
  payload: param,
});

export const getNewsDetails = (url, config, history) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateNewsDetails(resp.data));
    })
    .catch(function (error) {
      history.push("/404");
    });
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_NEWS_DETAILS":
      return { ...state, newsDetails: action.payload };
    default:
      return state;
  }
};
