import React from "react";

export default function Right({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 15.093 25.69">
      <g transform="translate(-101.478)" opacity="0.902">
        <g transform="translate(101.478)">
          <path
            d="M116.161,11.843,104.728.41a1.408,1.408,0,0,0-1.988,0l-.842.842a1.407,1.407,0,0,0,0,1.988l9.6,9.6-9.611,9.611a1.409,1.409,0,0,0,0,1.988l.842.841a1.408,1.408,0,0,0,1.988,0l11.443-11.443a1.418,1.418,0,0,0,0-1.994Z"
            transform="translate(-101.478 0)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
}
