import Axios from "axios";

const INITIAL_STATE = {
  plenary: [
    {
      id: null,
      title: null,
      plenary: [
        {
          id: null,
          name: null,
          occupation: null,
          number: null,
          image_path: null,
          position: 4,
        },
      ],
    },
  ],
};

export const updatePlenary = param => ({
  type: "SET_PLENARY",
  payload: param,
});

export const getPlenary = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updatePlenary(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PLENARY":
      return { ...state, plenary: action.payload };
    default:
      return state;
  }
};
