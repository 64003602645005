import React from "react";
import GoogleMapReact from "google-map-react";

import { Container } from "./styles";

import Marker from "./../../assets/icons/marker";

export default function Map() {
  const defaultPropsMaps = {
    center: {
      lat: parseFloat(process.env.REACT_APP_GOOGLE_MAPS_LAT),
      lng: parseFloat(process.env.REACT_APP_GOOGLE_MAPS_LNG),
    },
    zoom: 15,
    key: process.env.REACT_APP_GOOGLE_KEY,
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  return (
    <Container>
      <GoogleMapReact bootstrapURLKeys={{ key: defaultPropsMaps.key }} defaultCenter={defaultPropsMaps.center} defaultZoom={defaultPropsMaps.zoom}>
        <AnyReactComponent
          lat={defaultPropsMaps.center.lat}
          lng={defaultPropsMaps.center.lng}
          text={<Marker width={19.872} height={27.435} fillColor="#D9473D" />}
        />
      </GoogleMapReact>
    </Container>
  );
}
