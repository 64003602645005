import React from "react";

export default function MenuClouse(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 100 50">
      <g transform="translate(0 -96.243)">
        <g transform="translate(0 96.243)">
          <path d="M3.125,102.493h93.75a3.125,3.125,0,0,0,0-6.25H3.125a3.125,3.125,0,0,0,0,6.25Z" transform="translate(0 -96.243)" fill="#fff" />
          <path d="M96.875,180.455H3.125a3.125,3.125,0,1,0,0,6.25h93.75a3.125,3.125,0,1,0,0-6.25Z" transform="translate(0 -158.58)" fill="#fff" />
          <path d="M185.928,264.667h-62.5a3.125,3.125,0,1,0,0,6.25h62.5a3.125,3.125,0,1,0,0-6.25Z" transform="translate(-120.053 -220.917)" fill="#fff" />
        </g>
      </g>
    </svg>
  );
}
