import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  max-height: 100vh;

  background-color: #ffffff;
  background: #ffffff;

  @page {
    background-color: #ffffff;
    background: #ffffff;
    size: 29.7cm 21cm;
    margin: 0;
    padding: 0;
  }

  @media print {
    @page {
      size: landscape;
    }
  }

  .certificate {
    width: 29.7cm;
    height: 21cm;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 29.7cm;
    height: 21cm;
  }

  .content {
    position: absolute;
    padding: 100px;
    font-size: 16px;
    line-height: 31px;
    padding-left: 250px;
    text-align: justify;

    font-family: "NunitoSans-Regular";
  }

  .content strong {
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "NunitoSans-Bold";
  }
`;
