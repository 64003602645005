import React from "react";

export default function User({ width = "26.111", height = "22.346", fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 30.111 26.346">
      <g transform="translate(0 -0.008)">
        <path
          d="M91.605,12.554a6.273,6.273,0,1,1,6.273-6.273A6.28,6.28,0,0,1,91.605,12.554Zm0-10.664A4.391,4.391,0,1,0,96,6.281,4.4,4.4,0,0,0,91.605,1.89Zm0,0"
          transform="translate(-80.314)"
          fill={fillColor}
        />
        <path
          d="M21.642,267.3H.941A.941.941,0,0,1,0,266.358v-4.391a5.966,5.966,0,0,1,5.959-5.959H16.623a5.966,5.966,0,0,1,5.959,5.959v4.391A.941.941,0,0,1,21.642,267.3Zm-19.76-1.882H20.7v-3.45a4.082,4.082,0,0,0-4.077-4.077H5.959a4.082,4.082,0,0,0-4.077,4.077Zm0,0"
          transform="translate(0 -240.945)"
          fill={fillColor}
        />
        <path d="M310.273,188.558H299.609a.941.941,0,1,1,0-1.882h10.664a.941.941,0,0,1,0,1.882Zm0,0" transform="translate(-281.104 -175.69)" fill={fillColor} />
        <path
          d="M395.613,113.225a.942.942,0,0,1-.665-1.607l4.353-4.353-4.353-4.353a.941.941,0,0,1,1.331-1.331L401.3,106.6a.941.941,0,0,1,0,1.331l-5.018,5.018A.935.935,0,0,1,395.613,113.225Zm0,0"
          transform="translate(-371.462 -95.34)"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}
