import React, { useState } from "react";

import { Container, Title, Form, Input, Button } from "./styles";

import api from "./../../services/api";

const account_id = 62061;

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("Enviar");

  const send = async event => {
    event.preventDefault();

    setLoading("Enviando...");
    try {
      const { data } = await api.post(
        `https://emailmarketing.locaweb.com.br/api/v1/accounts/${account_id}/contacts`,
        {
          contact: {
            email: email,
            list_ids: ["228"],
            custom_fields: {},
          },
        },
        {
          headers: {
            "X-Auth-Token": "k181axHrkiP6dF5ybkGdwysf41jKdvMW9Vfbgz6zPekr",
          },
        },
      );

      if (data) {
        alert("E-mail cadastrado com sucesso em nosso banco de dados.");
      }
    } catch (error) {
      console.log(error);
      alert("Erro ao cadastrar e-mail. Tente novamente");
    }

    setLoading("Enviar");
  };

  return (
    <Container>
      <Title>
        Cadastre e receba nossa
        <br />
        Newsletter
      </Title>
      <Form onSubmit={send}>
        <Input type="email" placeholder="DIGITE SEU E-MAIL" required value={email} onChange={event => setEmail(event.target.value)} />
        <Button>{loading}</Button>
      </Form>
    </Container>
  );
}
