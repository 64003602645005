import styled from "styled-components";
import { Link } from "react-router-dom";

export const Main = styled.main`
  position: relative;
  margin-top: 170px;

  @media (max-width: 1366px) {
    margin-top: 152px;
  }
  @media (max-width: 1281px) {
    margin-top: 230px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 209px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    margin-top: 200px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    margin-top: 110px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Container = styled.section`
  padding: 40px 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: 1366px) {
    padding: 40px 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Aside = styled.aside`
  align-self: flex-start;

  width: 408px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    width: 90%;
    align-self: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentMain = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentBody = styled.div`
  flex: 1;
  width: 100%;
  margin-left: 90px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  max-width: 780px;

  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */

    margin-left: 0px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentBox = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 5px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    padding: 15px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    padding: 7px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
export const AvisoBox = styled.div`
  background-color: rgba(134, 32, 25, 0.2);
  border-left: 7px solid #862019;
  padding: 10px;
`;

export const AvisoTitle = styled.p`
  font-family: "NunitoSans-Bold";
  color: #862019;
  font-size: 20px;
`;

export const AvisoContent = styled.p`
  font-family: "NunitoSans-Regular";
  font-size: 16px;
`;

export const Form = styled.div`
  margin-top: 20px;
`;

export const FieldContainer = styled.div`
  margin-top: 20px;
  width: 100%;
`;

export const Label = styled.p`
  font-family: "NunitoSans-Regular";
  color: #ff0000;
`;

export const InputText = styled.input`
  height: 40px;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-family: "NunitoSans-Regular";
  border: none;
  border-bottom: 1px solid #dddddd;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-family: "NunitoSans-Regular";
  border: none;
  border-bottom: 1px solid #dddddd;
`;

export const Collumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const CollumnContainer = styled.div`
  width: 45%;
`;
export const HorarioContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const HorarioCollumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const HorarionButton = styled.button`
  margin: 10px;
  height: 40px;
  max-width: 140px;
  min-width: 100px;
  background-color: #f4f4fd;
  border: none;
  border-radius: 5px;
  color: #000;
  font-family: "NunitoSans-Bold";
  &:disabled {
    background-color: #f1f1f1;
    color: #a1a1a1;
  }
  &.active,
  &:hover {
    background-color: #862019;
    color: #f1f1f1;
    cursor: pointer;
  }
`;

export const EnviarContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const Enviar = styled.button`
  margin: 10px;
  height: 40px;
  max-width: 140px;
  min-width: 100px;
  background-color: #862019;
  border: none;
  border-radius: 5px;
  color: #f1f1f1;
  font-family: "NunitoSans-Bold";
  &:hover {
    background-color: #862019;
    color: #f1f1f1;
    cursor: pointer;
  }
`;
