import React from "react";

export default function Phone({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24.526 24.526">
      <path
        d="M22.558,16.1a13.925,13.925,0,0,1-4.373-.7,2,2,0,0,0-1.944.41l-2.758,2.082a15.24,15.24,0,0,1-6.852-6.851l2.02-2.686a1.982,1.982,0,0,0,.488-2.009,13.946,13.946,0,0,1-.7-4.38A1.97,1.97,0,0,0,6.472,0h-4.5A1.97,1.97,0,0,0,0,1.968,22.583,22.583,0,0,0,22.558,24.526a1.97,1.97,0,0,0,1.968-1.968V18.066A1.97,1.97,0,0,0,22.558,16.1Z"
        fill={fillColor}
      />
    </svg>
  );
}
