import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "viewerjs-react/dist/index.css";
import RViewerJS from "viewerjs-react";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../../store/banner.reducers";
import { getPartnerDetails } from "./../../../store/partners.details.reducers";
import { getDate, getDateByTimeZoneCba } from "./../../../services/functions";

import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import MainServices from "./../../../components/MainServices";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import Share from "./../../../components/Share";

import globe from "./../../../assets/icons/globe.svg";
import phone2 from "./../../../assets/icons/phone2.svg";
import map2 from "./../../../assets/icons/map2.svg";
import instagram from "./../../../assets/icons/instagram.svg";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
  ContentBody,
  ContentMain,
  ContentFigure,
  ContentImage,
  ContentBox,
  ContentTitle,
  ContentSummary,
  ContentLine,
  ContentZoom,
  ZoomPlus,
  ZoomLess,
} from "./styles";
import { ListItemLine } from "../styles";

const frontload = async props => {
  await props.getPartnerDetails(`${process.env.REACT_APP_API}/partners/${props.match.params.id ?? ""}`, {}, props.history);
  return;
};

function Details() {
  let { partnersDetailsReducers: details } = useSelector(state => state);
  details = details.partnerDetails;

  const history = useHistory();

  const [filter, setFilter] = useState("");

  const [fontSize, setFontSize] = useState(0);

  const getInstagram = link => {
    link = link.split(".instagram.com/");

    return `https://www.instagram.com/${link.pop()}`;
  };

  return (
    <>
      <Helmet>
        <title>{details.name}</title>
        <meta name="description" content={details.benefits} />
        <meta property="og:title" content={details.name} />
        <meta name="title" content={details.name} />
        <meta property="og:description" content={details.benefits} />
        <meta property="og:image" content={details.image_path} />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title uppercase={false} title={`${details.name}`} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <ContentZoom>
                    <ZoomPlus onClick={() => setFontSize(fontSize + 1)}>A+</ZoomPlus>
                    <ZoomLess onClick={() => setFontSize(fontSize - 1)}>A-</ZoomLess>
                    <Share />
                  </ContentZoom>

                  {details.image_path ? (
                    <RViewerJS className="image">
                      <ContentImage src={details.image_path} title={details.name} alt={details.name} />
                    </RViewerJS>
                  ) : (
                    <></>
                  )}

                  <ContentLine fontSize={fontSize}>{details?.segment?.name}</ContentLine>
                  <ContentTitle fontSize={fontSize}>{details.name}</ContentTitle>

                  <ContentSummary fontSize={fontSize} className="ck-content">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: details.benefits ?? "",
                      }}
                    />
                  </ContentSummary>

                  <div>
                    {details.instagram ? (
                      <ListItemLine>
                        <img style={{ width: 20, height: 20 }} src={instagram} alt="Site" />
                        <a href={details.instagram} target="_blank">
                          {getInstagram(details.instagram)}
                        </a>
                      </ListItemLine>
                    ) : null}

                    {details.site ? (
                      <ListItemLine>
                        <img src={globe} alt="Site" />
                        <a href={details.site} target="_blank">
                          {details.site}
                        </a>
                      </ListItemLine>
                    ) : null}

                    <ListItemLine>
                      <img src={phone2} alt="Telefone" />
                      {details.phones}
                    </ListItemLine>
                    <ListItemLine>
                      <img src={map2} alt="Endereço" /> {details.address}
                      {details.address_number ? `, ${details.address_number}` : ``}
                      {details.address_neighborhood ? `, ${details.address_neighborhood}` : ``}
                      {details.address_city ? `, ${details.address_city}` : ``}
                      {details.address_state ? ` - ${details.address_state}` : ``}
                    </ListItemLine>
                  </div>

                  {/* </div> */}
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    partnerDetails: state.partnersDetailsReducers.partnerDetails,
  };
};
const mapDispatchToProps = {
  getBanner,
  getPartnerDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Details),
);
