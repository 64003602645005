import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Container, Figure, Image, Button } from "./styles";

import Left from "./../../assets/icons/arrows/left";
import Right from "./../../assets/icons/arrows/right";

let timer;
export default function Featured() {
  let { bannerReducers: banner } = useSelector(state => state);
  banner = banner.banner;

  const [active, setActive] = useState(0);
  const [countSlide, setCountSlide] = useState(banner.length - 1);

  async function handleNext() {
    clearTimeout(timer);
    if (active >= countSlide) {
      await setActive(0);
    } else {
      await setActive(active + 1);
    }
  }

  async function handlePrev() {
    clearTimeout(timer);
    if (active <= 0) {
      await setActive(countSlide);
    } else {
      await setActive(active - 1);
    }
  }

  useEffect(() => {
    timer = setTimeout(() => {
      handleNext();
    }, 10000);
    return () => clearTimeout(timer);
  }, [active]);

  return (
    <>
      {banner[0].id ? (
        <Container id="featured">
          <Button onClick={() => handlePrev()} style={{ display: banner.length > 1 ? "block" : "none" }}>
            <Left width={29.78} height={50.69} fillColor="#FFFFFF" />
          </Button>
          {banner.map(({ id, image_path, target, title, uri }, index) => (
            <>
              <Figure key={id} style={{ display: active === index ? `block` : `none` }}>
                {uri ? (
                  <>
                    {uri.substr(0, 4) === "http" ? (
                      <a href={uri} title={title} alt={title} target={target} rel="noopener noreferrer">
                        <Image src={image_path} alt={title} />
                      </a>
                    ) : (
                      <Link to={uri} title={title} alt={title} target={target}>
                        <Image src={image_path} alt={title} />
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    <Image src={image_path} alt={title} />
                  </>
                )}
              </Figure>
            </>
          ))}
          <Button right onClick={() => handleNext()} style={{ display: banner.length > 1 ? "block" : "none" }}>
            <Right width={29.78} height={50.69} fillColor="#FFFFFF" />
          </Button>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
