import { combineReducers } from "redux";

import configReducers from "./config.reducers";
import navReducers from "./nav.reducers";
import bannerReducers from "./banner.reducers";
import serviceReducers from "./service.reducers";
import magazineReducers from "./magazine.reducers";
import eventsReducers from "./events.reducers";
import eventDetailsReducers from "./event.details.reducers";
import newsReducers from "./news.reducers";
import newsDetailsReducers from "./news.details.reducers";
import partnersDetailsReducers from "./partners.details.reducers";
import scheduleReducers from "./schedule.reducers";
import scheduleDetailsReducers from "./schedule.details.reducers";
import coursesReducers from "./courses.reducers";
import coursesDetailsReducers from "./courses.details.reducers";
import partnersReducers from "./partners.reducers";
import representativesReducers from "./representatives.reducers";
import regionalReducers from "./regional.reducers";
import teamReducers from "./team.reducers";
import adsReducers from "./ads.reducers";
import adsDetailsReducers from "./ads.details.reducers";
import filesReducers from "./files.reducers";
import plenaryReducers from "./plenary.reducers";
import videosReducers from "./videos.reducers";
import videoDetailsReducers from "./video.details.reducers";
import presidentsReducers from "./presidents.reducers";
import downloadsReducers from "./downloads.reducers";

export default combineReducers({
  configReducers,
  navReducers,
  bannerReducers,
  serviceReducers,
  magazineReducers,
  eventsReducers,
  eventDetailsReducers,
  newsReducers,
  newsDetailsReducers,
  partnersDetailsReducers,
  coursesReducers,
  coursesDetailsReducers,
  scheduleReducers,
  scheduleDetailsReducers,
  partnersReducers,
  representativesReducers,
  regionalReducers,
  teamReducers,
  adsReducers,
  filesReducers,
  plenaryReducers,
  adsDetailsReducers,
  videosReducers,
  videoDetailsReducers,
  presidentsReducers,
  downloadsReducers,
});
