import React, { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";

import api from "./../../services/api";
import { getDate, getDateByTimeZoneCba } from "./../../services/functions";

import { Container, CalendarFooter, CalendarElement } from "./styles";

const Calendar = ({ style }) => {
  const [date, setDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const [datesImmutable, setDatesImmutable] = useState([]);

  const history = useHistory();

  useEffect(() => {
    async function loadDates() {
      try {
        const { data } = await api.get("/schedule/calendar", {});
        setDatesImmutable(data);
        const reg = data.map(item => ({ date: getDate(item.start_date, "d 'de' MMMM 'de' yyyy"), count: parseInt(item.count) }));
        setDates(reg);
      } catch (err) {
        console.log(err);
      }
    }

    loadDates();
  }, []);

  const handleClick = param => {
    setDate(param);

    datesImmutable.map(item => {
      if (getDateByTimeZoneCba(item.start_date, "dd'/'MM'/'yyyy") === getDateByTimeZoneCba(param, "dd'/'MM'/'yyyy")) {
        history.push(`/agenda/data/${getDateByTimeZoneCba(item.start_date, "dd'-'MM'-'yyyy")}/`);
      }
    });
  };

  return (
    <Container style={style}>
      <CalendarElement dates={dates} onChange={datei => handleClick(datei)} value={date} hover={date} />
      <CalendarFooter to="/agenda" title="Ver agenda completa" alt="Ver agenda completa">
        Ver toda agenda
      </CalendarFooter>
    </Container>
  );
};

export default memo(Calendar);
