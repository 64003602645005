import React from "react";

import { Header, H1 } from "./styles";

export default function Title({ title, uppercase = true }) {
  return (
    <Header>
      <H1 uppercase={uppercase}>{title}</H1>
    </Header>
  );
}
