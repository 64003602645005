import React from "react";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getPlenary } from "./../../store/plenary.reducers";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";

import {
  Main,
  Container,
  Content,
  ContentBody,
  ContentMain,
  SubTitle,
  Items,
  Item,
  ItemFigure,
  ItemImage,
  ItemTitle,
  ItemSubTitle,
  ItemNumber,
} from "./styles";

const frontload = async props => {
  await props.getPlenary(`${process.env.REACT_APP_API}/plenary/types`, {});
  return;
};

function Plenary() {
  let { plenaryReducers: plenary } = useSelector(state => state);
  plenary = plenary.plenary;

  return (
    <>
      <Helmet>
        <title>Plenário - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Plenário - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Plenário - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Plenário`} />
            <ContentMain>
              <ContentBody>
                {plenary.map(item => (
                  <>
                    <SubTitle>{item.title}</SubTitle>
                    <Items>
                      {item.plenary.map(plenaryItem => (
                        <Item>
                          <ItemFigure>
                            <ItemImage src={plenaryItem.image_path} title={plenaryItem.name} alt={plenaryItem.name} />
                          </ItemFigure>
                          <ItemTitle>{plenaryItem.name}</ItemTitle>
                          <ItemSubTitle>{plenaryItem.occupation}</ItemSubTitle>
                          <ItemNumber>{plenaryItem.number}</ItemNumber>
                        </Item>
                      ))}
                    </Items>
                  </>
                ))}
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    plenary: state.plenaryReducers.plenary,
  };
};
const mapDispatchToProps = {
  getBanner,
  getPlenary,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Plenary),
);
