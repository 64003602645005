import styled from "styled-components";

export const Main = styled.main`
  position: relative;
  margin-top: 170px;

  @media (max-width: 1366px) {
    margin-top: 152px;
  }
  @media (max-width: 1281px) {
    margin-top: 230px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 209px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    margin-top: 200px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    margin-top: 110px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Container = styled.section`
  padding: 40px 140px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

  @media (max-width: 1366px) {
    padding: 40px 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
`;

export const Aside = styled.aside`
  align-self: flex-start;

  width: 408px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    width: 90%;
    align-self: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const FilterLabel = styled.label`
  color: #3c3535;
  font-size: 20px;
  line-height: 27px;
  font-family: "NunitoSans-Bold";

  margin-bottom: 20px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 8px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const FilterInput = styled.input`
  color: #3c3535;
  font-size: 20px;
  line-height: 27px;
  font-family: "NunitoSans-Light";

  border: 0;
  border-bottom: 0.2px solid rgba(60, 53, 53, 0.42);

  height: 30px;
`;

export const FilterButton = styled.button`
  font-size: 21px;
  letter-spacing: 2px;
  line-height: 28px;
  font-family: "NunitoSans-Regular";
  text-transform: uppercase;
  color: #ffffff;

  border: 0;
  border-radius: 5px;

  background-color: #861811;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  padding: 13px 43px;
  margin-top: 60px;

  cursor: pointer;
  transition: 0.5s;

  &:hover {
    border-radius: 20px;
    opacity: 0.7;
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 23px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxTitle = styled.div`
  background-color: #eaeaea;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #3c3535;
  font-family: "NunitoSans-ExtraBold";
  font-size: 19px;
  line-height: 35px;
  text-transform: uppercase;

  padding: 22px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 30px;
    padding: 2px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 45px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    padding: 13px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Box = styled.div`
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  margin-bottom: 50px;
`;

export const CategoriaLists = styled.nav``;

export const CategoriaList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const CategoriaItem = styled.li`
  font-family: "NunitoSans-SemiBold";
  font-size: 19px;
  line-height: 35px;
  text-transform: uppercase;
  color: #3c3535;

  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &::before {
    content: " ";
    width: 30px;
    height: 30px;

    background-color: ${props => props.color};

    margin-right: 20px;

    transition: 0.6s ease-in-out;
  }

  &:hover {
    color: ${props => props.color};

    &::before {
      border-radius: 40px;
    }
  }
`;

export const ContentMain = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentBody = styled.div`
  flex: 1;
  margin-left: 90px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 1366px) {
    margin-left: 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-left: 0px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentFigure = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const ContentImage = styled.img`
  width: 100%;
`;

export const ContentBox = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 15px 45px 45px 45px;
`;

export const ContentTitle = styled.h3`
  font-family: "NunitoSans-SemiBold";
  font-size: ${props => 26 + props.fontSize}px;
  line-height: ${props => 32 + props.fontSize}px;
  color: #3c3535;
`;

export const ContentSummary = styled.div`
  font-family: "NunitoSans-Regular";
  font-size: ${props => 18 + props.fontSize}px;
  line-height: ${props => 38 + props.fontSize}px;
  color: #3c3535;

  margin-top: 28px;
  margin-bottom: 30px;

  * {
    line-height: ${props => 38 + props.fontSize}px;
  }
`;

export const ContentLine = styled.div`
  font-family: "NunitoSans-Light";
  font-size: ${props => 18 + props.fontSize}px;
  line-height: ${props => 24 + props.fontSize}px;
  color: #d9473d;
`;

export const ContentZoom = styled.div`
  border-bottom: 0.5px solid rgba(212, 212, 212, 0.75);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin-bottom: 10px;
`;

export const ZoomPlus = styled.button`
  font-family: "NunitoSans-SemiBold";
  font-size: 20px;
  line-height: 54px;
  color: #3c3535;

  background-color: transparent;
  border: 0;

  cursor: pointer;

  margin-right: 10px;

  &:hover {
    color: #861811;
  }
`;

export const ZoomLess = styled.button`
  font-family: "NunitoSans-SemiBold";
  font-size: 16px;
  line-height: 54px;
  color: #3c3535;

  background-color: transparent;
  border: 0;

  cursor: pointer;

  &:hover {
    color: #861811;
  }
`;
