import Axios from "axios";

const INITIAL_STATE = {
  ads: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        id: null,
        title: null,
        name: null,
        cro: null,
        tel: null,
        email: null,
        description: null,
        image_path: null,
        ads_type_id: null,
        uri: null,
        type: { name: "" },
      },
    ],
  },
};

export const updateAds = param => ({
  type: "SET_ADS",
  payload: param,
});

export const getAds = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateAds(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ADS":
      return { ...state, ads: action.payload };
    default:
      return state;
  }
};
