import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getCourses } from "./../../store/courses.reducers";
import { maskCpfCnpj, handlePaginateChange, handleGetPaginate, stripAccents, getDateByTimeZoneCba, getDate } from "./../../services/functions";
import api from "./../../services/api";
import { isAuthenticated, getToken, login, logout, getId } from "./../../services/auth";

import Header from "./../../components/Header";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";

import Forgot from "./../../assets/icons/forgot";
import User from "./../../assets/icons/user";
import Check from "./../../assets/icons/check";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemDate,
  ListItemSummary,
  ListItemLink,
  BoxContentLink,
  AsideButton,
} from "./styles";

let PATH = "/cursos/";
const frontload = async props => {
  await props.getCourses(`${process.env.REACT_APP_API}/courses`, {
    headers: {
      pagenumber: props.match.params.page ?? 1,
      pagelimit: 10,
      term: stripAccents(props.match.params.filter ?? ""),
      datee: props.match.params.date ?? "",
      type: props.match.params.category ?? "",
    },
  });
  return;
};

function Courses(props) {
  if (props.match.params.filter) PATH = `/cursos/buscar/${props.match.params.filter}/`;
  if (props.match.params.date) PATH = `/cursos/data/${props.match.params.date}/`;
  if (props.match.params.category) PATH = `/cursos/categoria/${props.match.params.category}/`;

  let { coursesReducers: courses } = useSelector(state => state);
  const paginate = courses.courses;
  courses = courses.courses.data;

  const [filter, setFilter] = useState();
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [logged, setLogged] = useState(false);

  const history = useHistory();

  const handleSubmitLogin = async event => {
    event.preventDefault();
    try {
      const response = await api.post("/site_users/session", { cpf, password });

      if (response.data) {
        login(response.data.id, response.data.email, response.data.name);
        setLogged(true);
      } else {
        alert("Erro desconhecido, tente novamente");
      }
    } catch (error) {
      console.log(error);
      alert("Usuário ou Senha Inválidos");
      if (error.response.status) {
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Fique por dentro da nossa agenda de cursos - {process.env.REACT_APP_TITLE} {paginate.page ? `- Pág. ${paginate.page}` : ""}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta
          property="og:title"
          content={`Fique por dentro da nossa agenda de cursos - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`}
        />
        <meta
          name="title"
          content={`Fique por dentro da nossa agenda de cursos - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`}
        />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Cursos`} />
            <ContentMain>
              <Aside>
                {logged ? (
                  <></>
                ) : (
                  <>
                    <Box id="box-login">
                      <BoxTitle>Login Cursos</BoxTitle>
                      <BoxContent autoComplete="off" autocomplete="off" id="formLogin">
                        <FilterLabel>CPF</FilterLabel>
                        <FilterInput key={"cpf"} type="text" name="cpf" id="cpf" value={cpf} required onChange={event => setCpf(maskCpfCnpj(event.target))} />
                        <FilterLabel style={{ marginTop: "20px" }}>SENHA</FilterLabel>
                        <FilterInput
                          key={"password"}
                          type="password"
                          id="password"
                          value={password}
                          required
                          onChange={event => setPassword(event.target.value)}
                        />
                        <FilterButton key={'submit'} type="submit" onClick={handleSubmitLogin} id="loginButtom">
                          ENTRAR
                        </FilterButton>
                      </BoxContent>
                      <BoxContent style={{ paddingTop: 0 }}>
                        <BoxContentLink to="/usuario/recupere-sua-conta">
                          <Forgot /> Esqueceu a senha?
                        </BoxContentLink>

                        <BoxContentLink to={`/usuario/criar-uma-conta/`}>
                          <User /> Crie sua conta
                        </BoxContentLink>
                      </BoxContent>
                    </Box>
                  </>
                )}

                <AsideButton to="/emitir-certificados">
                  <Check width={35.96} height={40.88} fillColor="#FFFFFF" />
                  Autenticar Certificado
                </AsideButton>

                {logged ? (
                  <>
                    <AsideButton to="/usuario/meus-dados">
                      <User fillColor="#FFFFFF" /> MEUS DADOS
                    </AsideButton>
                  </>
                ) : (
                  <></>
                )}

                <Box>
                  <BoxTitle>Pesquisar cursos</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por nome, local, descrição</FilterLabel>
                    <FilterInput type="text" value={filter} onChange={event => setFilter(event.target.value)} />
                    <FilterButton
                      onClick={() => {
                        history.push(`/cursos/buscar/${filter}`);
                      }}>
                      Pesquisar
                    </FilterButton>
                  </BoxContent>
                </Box>
              </Aside>
              <ContentBody>
                {courses.map(course => (
                  <ListItem key={Math.random()}>
                    {course.image_path ? (
                      <Link to={`/cursos/detalhes/${course.uri}`}>
                        <ListItemFigure title={course.name} alt={course.name}>
                          <ListItemImage src={course.image_path} title={course.name} alt={course.name} />
                        </ListItemFigure>
                      </Link>
                    ) : (
                      <></>
                    )}

                    <ListItemContent>
                      <Link to={`/cursos/detalhes/${course.uri}`}>
                        <ListItemTitle>{course.name}</ListItemTitle>
                      </Link>

                      <Link to={`/cursos/detalhes/${course.uri}`}>
                        {course.id > 50 ? (
                          <ListItemDate>{getDateByTimeZoneCba(course.start_date, "dd'/'MM'/'yyyy 'às' HH':'mm'hrs' ")}</ListItemDate>
                        ) : (
                          <ListItemDate>{getDate(course.start_date, "dd'/'MM'/'yyyy 'às' HH':'mm'hrs' ")}</ListItemDate>
                        )}
                      </Link>
                      <Link to={`/cursos/detalhes/${course.uri}`}>
                        <ListItemSummary>{course.summary}</ListItemSummary>
                      </Link>
                      <ListItemLink to={`/cursos/detalhes/${course.uri}`}>CLIQUE PARA SABER MAIS</ListItemLink>
                    </ListItemContent>
                  </ListItem>
                ))}

                <Pagination
                  activePage={+paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={+paginate.perPage}
                  totalItemsCount={+paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    courses: state.coursesReducers.courses,
  };
};
const mapDispatchToProps = {
  getBanner,
  getCourses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Courses),
);
