import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

/* import store */
import { getBanner } from "./../../../store/banner.reducers";

/* import services */
import api from "./../../../services/api";
import { logout, login, isAuthenticated, getId } from "./../../../services/auth";

/* import functions */
import { maskCep, maskTelephone89Digitos, maskCpfCnpj } from "./../../../services/functions";

/* import components */
import Header from "./../../../components/Header";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import MainServices from "./../../../components/MainServices";

import Logout from "./../../../assets/icons/logout";

/* import styles */
import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ContentBox,
  Form,
  GroupInput,
  InputLabel,
  Input,
  BorderInput,
  FormButton,
  AsideButton,
} from "./styles";

const frontload = async props => {
  return;
};

function AccountData() {
  const recaptchaRef = React.useRef();
  const history = useHistory();

  const [activeInput, setActiveInput] = useState("name");
  const [borderInputPosition, setBorderInputPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [address_cep, setAddress_cep] = useState("");
  const [address, setAddress] = useState("");
  const [address_city, setAddress_city] = useState("");
  const [address_state, setAddress_state] = useState("");
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  async function setFocus(index) {
    await setActiveInput(index);
    handleScrollForm();
  }

  function handleScrollForm() {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`.active`)) {
        const offset = global.window.document.querySelector(`.active`).offsetTop;
        setBorderInputPosition(offset - 18);
      }
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    if (password || passwordConfirm) {
      if (password !== passwordConfirm) {
        alert("Por favor confirme a sua senha corretamente.");
        return false;
      }
    }

    setLoading(true);
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      onResolvedRecaptcha();
    } else {
      alert("Erro! Tente novamente");
      console.log("erro recaptcha: ", token);
      setLoading(false);
    }
  };

  const onResolvedRecaptcha = async () => {
    try {
      const id = getId();

      const { data } = await api.put(`/site_users/${id}`, {
        name,
        cpf,
        email,
        address_cep,
        address,
        address_city,
        address_state,
        tel,
        password,
        origin: "site",
      });
      login(data.id, data.email, data.name);

      alert("Informações atualizadas com sucesso!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Erro ao tentar atualizar as informações\nTente novamente");
    }
  };

  const handleLogout = () => {
    logout();

    history.push("/cursos");
  };

  const loadData = async () => {
    try {
      const id = getId();

      const { data } = await api.get(`/site_users/${id}`, {});
      if (data) {
        setName(data.name);
        setCpf(data.cpf);
        setEmail(data.email);
        setAddress_cep(data.address_cep);
        setAddress(data.address);
        setAddress_city(data.address_city);
        setAddress_state(data.address_state);
        setTel(data.tel);
      }
    } catch (error) {
      alert("Desculpe, ocorreu um erro ao consultar seus dados!\nTente Novamente.");
    }
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      history.goBack();
    }

    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Atualize seus dados e se inscreva em nossos cursos - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Atualize seus dados e se inscreva em nossos cursos - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Atualize seus dados e se inscreva em nossos cursos - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title="Atualize seu dados" />
            <ContentMain>
              <Aside>
                <AsideButton onClick={() => handleLogout()}>
                  <Logout />
                  Sair
                </AsideButton>

                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <Form autoComplete="off" onSubmit={handleSave}>
                    <BorderInput style={{ top: borderInputPosition }}></BorderInput>

                    <GroupInput className={activeInput === "name" ? `active` : ""}>
                      <InputLabel for="name" required>
                        Nome Completo *
                      </InputLabel>
                      <Input id="name" type="text" required onFocus={() => setFocus("name")} value={name} onChange={event => setName(event.target.value)} />
                    </GroupInput>

                    <GroupInput className={activeInput === "cpf" ? `active` : ""}>
                      <InputLabel for="cpf" required>
                        CPF *
                      </InputLabel>
                      <Input
                        id="cpf"
                        type="text"
                        required
                        onFocus={() => setFocus("cpf")}
                        value={cpf}
                        onChange={event => setCpf(maskCpfCnpj(event.target))}
                        onKeyUp={event => setCpf(maskCpfCnpj(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "email" ? `active` : ""}>
                      <InputLabel for="email" required>
                        E-mail *
                      </InputLabel>
                      <Input
                        id="email"
                        type="email"
                        required
                        onFocus={() => setFocus("email")}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        style={{ textTransform: "none" }}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "tel" ? `active` : ""}>
                      <InputLabel for="tel">Telefone</InputLabel>
                      <Input
                        id="tel"
                        type="text"
                        onFocus={() => setFocus("tel")}
                        value={tel}
                        onChange={event => setTel(maskTelephone89Digitos(event.target))}
                        onKeyUp={event => setTel(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_cep" ? `active` : ""}>
                      <InputLabel for="address_cep">CEP</InputLabel>
                      <Input
                        id="address_cep"
                        type="text"
                        onFocus={() => setFocus("address_cep")}
                        value={address_cep}
                        onChange={event => setAddress_cep(maskCep(event.target))}
                        onKeyUp={event => setAddress_cep(maskCep(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address" ? `active` : ""}>
                      <InputLabel for="address">Endereço</InputLabel>
                      <Input
                        id="address"
                        type="text"
                        onFocus={() => setFocus("address")}
                        value={address}
                        onChange={event => setAddress(event.target.value)}
                        onKeyUp={event => setAddress(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_city" ? `active` : ""}>
                      <InputLabel for="address_city" required>
                        Cidade *
                      </InputLabel>
                      <Input
                        id="address_city"
                        type="text"
                        onFocus={() => setFocus("address_city")}
                        value={address_city}
                        onChange={event => setAddress_city(event.target.value)}
                        onKeyUp={event => setAddress_city(event.target.value)}
                        required
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_state" ? `active` : ""}>
                      <InputLabel for="address_state" required>
                        UF *
                      </InputLabel>
                      <Input
                        id="address_state"
                        type="text"
                        onFocus={() => setFocus("address_state")}
                        value={address_state}
                        onChange={event => setAddress_state(event.target.value)}
                        onKeyUp={event => setAddress_state(event.target.value)}
                        required
                        maxLength={2}
                        style={{ textTransform: "uppercase" }}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "password" ? `active` : ""}>
                      <InputLabel for="password">
                        Senha <small>deixe vazio caso não queria atualizar a sua senha</small>
                      </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        onFocus={() => setFocus("password")}
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "passwordConfirm" ? `active` : ""}>
                      <InputLabel for="passwordConfirm">Confirme a Senha</InputLabel>
                      <Input
                        id="passwordConfirm"
                        type="password"
                        onFocus={() => setFocus("passwordConfirm")}
                        value={passwordConfirm}
                        onChange={event => setPasswordConfirm(event.target.value)}
                      />
                    </GroupInput>

                    <FormButton disabled={loading ? true : false}>{loading ? "Atualizando informações... " : "Atualizar"}</FormButton>

                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" />
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(AccountData),
);
