import React from "react";

export default function Clock({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 28.808 28.809">
      <path
        d="M14.4,0a14.4,14.4,0,1,0,14.4,14.4A14.421,14.421,0,0,0,14.4,0Zm0,27.008a12.6,12.6,0,1,1,12.6-12.6,12.618,12.618,0,0,1-12.6,12.6Z"
        fill={fillColor}
      />
      <path
        d="M209.595,83.118h-1.8v9.375l5.665,5.665,1.273-1.273-5.138-5.138Z"
        transform="translate(-194.29 -77.716)"
        fill={fillColor}
      />
    </svg>
  );
}
