import dotenv from "./../../configs/dotenv";

import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";

import {
  Main,
  Container,
  Content,
  Services,
  ServiceA,
  Service,
  ServiceFigure,
  ServiceImage,
  ServiceTitle,
  ServiceFooter,
  News,
  New,
  NewFigure,
  NewImage,
  NewDate,
  NewTitle,
  NewsFooter,
  Galleries,
  Gallery,
  GalleryFigure,
  GalleryImage,
  GalleryTitle,
  GalleryFooter,
} from "./styles";

import { getBanner } from "./../../store/banner.reducers";
import { getService } from "./../../store/service.reducers";
import { getMagazine } from "./../../store/magazine.reducers";
import { getEvents } from "./../../store/events.reducers";
import { getNews } from "./../../store/news.reducers";
import { getSchedule } from "./../../store/schedule.reducers";

import { getDate, getDate2, getDateByTimeZoneCba } from "./../../services/functions";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import ScheduleBox from "./../../components/ScheduleBox";
import Title from "./../../components/Title";
import Calendar from "./../../components/Calendar";
import Magazine from "./../../components/Magazine";
import Map from "./../../components/Map";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import Popup from "./../../components/Popup";

const frontload = async props => {
  //  await props.getConfig(`${process.env.REACT_APP_API}/site_config`);
  await props.getBanner(`${process.env.REACT_APP_API}/banners`);
  await props.getService(`${process.env.REACT_APP_API}/services`);
  await props.getMagazine(`${process.env.REACT_APP_API}/magazines`, {
    headers: { pagenumber: 1, pagelimit: 1 },
  });
  await props.getEvents(`${process.env.REACT_APP_API}/events`, {
    headers: { pagenumber: 1, pagelimit: 6 },
  });
  await props.getNews(`${process.env.REACT_APP_API}/news`, {
    headers: { pagenumber: 1, pagelimit: 3 },
  });
  await props.getSchedule(`${process.env.REACT_APP_API}/schedule`, {
    headers: { pagenumber: 1, pagelimit: 10 },
  });

  return;
};

function Home() {
  let {
    serviceReducers: service,
    magazineReducers: magazine,
    eventsReducers: events,
    newsReducers: news,
    scheduleReducers: schedule,
  } = useSelector(state => state);
  service = service.service;
  magazine = magazine.magazine.data[0];
  events = events.events.data;
  news = news.news.data;
  schedule = schedule.schedule.data;

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={process.env.REACT_APP_TITLE} />
        <meta name="title" content={process.env.REACT_APP_TITLE} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />
      <Featured />
      <Main id="main">
        {schedule.length >= 4 ? <ScheduleBox /> : <></>}

        <Container>
          <Content>
            <Title title={`Serviços Principais`} />
            <Services>
              {service.map(({ id, name, title, image_path, target, uri }) => (
                <>
                  {uri.substr(0, 4) === "http" ? (
                    <ServiceA key={id} href={uri} title={title} alt={title} target={target} rel="noopener noreferrer">
                      <ServiceImage src={image_path} title={title} alt={title} />
                      {/* <ServiceFigure>
                      </ServiceFigure> */}
                      <ServiceTitle>{name}</ServiceTitle>
                      {/* <ServiceFooter>Acessar</ServiceFooter> */}
                    </ServiceA>
                  ) : (
                    <Service key={id} target={target} to={uri} title={title} alt={title}>
                      <ServiceImage src={image_path} title={title} alt={title} />

                      <ServiceTitle>{name}</ServiceTitle>
                      {/* <ServiceFooter>Acessar</ServiceFooter> */}
                    </Service>
                  )}
                </>
              ))}
            </Services>
          </Content>
        </Container>

        <Container>
          <Content style={{ flex: 1 }}>
            <Title title={`Últimas Notícias`} />
            <News>
              {news.map(({ id, title, image_path, uri, date, summary }) => (
                <New key={id}>
                  <NewFigure>
                    <Link to={`/noticias/detalhes/${uri}`} title={title} alt={title}>
                      <NewImage src={image_path} title={title} alt={title} />
                    </Link>
                    <NewDate>
                      <span>{getDateByTimeZoneCba(date, "dd")}</span>
                      <small>{getDateByTimeZoneCba(date, "MMM")}</small>
                    </NewDate>
                  </NewFigure>
                  <Link to={`/noticias/detalhes/${uri}`} title={title} alt={title}>
                    <NewTitle>{title}</NewTitle>
                  </Link>
                </New>
              ))}
            </News>
            <NewsFooter to="/noticias" title="Ver todos as notícias do CRO-MT" alt="Ver todos as notícias do CRO-MT">
              Ver Todas as Notícias
            </NewsFooter>
          </Content>

          <Content style={{ flex: "initial", marginLeft: "42px" }} className="hide-1281">
            <Title title={`Agenda`} />
            <Calendar />
          </Content>
        </Container>

        <Container id="galleries">
          <Content style={{ flex: 1 }}>
            <Title title={`Galeria de Fotos`} />
            <Galleries>
              {events.map(({ id, title, image_path, uri }) => (
                <Gallery key={id}>
                  <Link style={{ display: "flex", flexDirection: "column" }} to={`/eventos/detalhes/${uri}`}>
                    <GalleryFigure>
                      <GalleryImage title={title} alt={title} src={image_path} />
                    </GalleryFigure>
                    <GalleryTitle>
                      {title}
                      <br />
                    </GalleryTitle>
                  </Link>
                </Gallery>
              ))}
            </Galleries>
            <GalleryFooter to="/eventos" title="Ver todos os eventos do CRO-MT" alt="Ver todos os eventos do CRO-MT">
              Ver toda Galeria
            </GalleryFooter>
          </Content>

          <Content style={{ flex: "initial", marginLeft: "42px" }} className="hide-1281">
            <Title title={`Revista CRO-MT`} />
            <Magazine image={magazine.image_path} title={magazine.title} alt={magazine.title} uri={magazine.file_path} />
          </Content>
        </Container>

        <Map />

        <Newsletter />
      </Main>
      <Footer />

      <Popup />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    service: state.serviceReducers.service,
    magazine: state.magazineReducers.magazine,
    events: state.eventsReducers.events,
    news: state.newsReducers.news,
    schedule: state.scheduleReducers.schedule,
  };
};
const mapDispatchToProps = {
  getBanner,
  getService,
  getMagazine,
  getEvents,
  getNews,
  getSchedule,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Home),
);
