import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { Helmet } from "react-helmet";

import GoogleMapReact from "google-map-react";

/* import store */
import { getBanner } from "./../../store/banner.reducers";

/* import services */
import api from "./../../services/api";

/* import functions */
import { maskTelephone89Digitos } from "./../../services/functions";

/* import components */
import Header from "./../../components/Header";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";

/* import icons */
import Clock from "./../../assets/icons/clock";
import Email from "./../../assets/icons/email";
import Phone from "./../../assets/icons/phone";
import marker from "./../../assets/icons/marker.svg";
import Marker from "./../../assets/icons/marker";

/* import styles */
import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  BoxLists,
  BoxList,
  BoxItem,
  ContentBody,
  ContentMain,
  ContentBox,
  Form,
  GroupInput,
  InputLabel,
  Input,
  Textarea,
  BorderInput,
  FormButton,
  AsideButton,
} from "./styles";

const frontload = async props => {
  return;
};

function Contact() {
  const recaptchaRef = React.useRef();
  let { configReducers: config } = useSelector(state => state);
  config = config.config;

  const defaultPropsMaps = {
    center: {
      lat: parseFloat(config.latitude),
      lng: parseFloat(config.longitude),
    },
    zoom: 15,
    key: process.env.REACT_APP_GOOGLE_KEY,
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const [activeInput, setActiveInput] = useState("name");
  const [borderInputPosition, setBorderInputPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [message, setMessage] = useState("");

  async function setFocus(index) {
    await setActiveInput(index);
    handleScrollForm();
  }

  function handleScrollForm() {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`.active`)) {
        const offset = global.window.document.querySelector(`.active`).offsetTop;
        setBorderInputPosition(offset - 18);
      }
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    setLoading(true);
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      onResolvedRecaptcha();
    } else {
      alert("Erro! Tente novamente");
      console.log("erro recaptcha: ", token);
      setLoading(false);
    }
  };

  const onResolvedRecaptcha = async () => {
    try {
      await api.post("/contacts", {
        name,
        telephone,
        email,
        message,
      });

      setLoading(false);

      alert("Recebemos seu contato com sucesso.");
      document.location.reload();
    } catch (error) {
      console.log(error);
      alert("Erro ao enviar contato. Tente novamente!");
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Fale conosco - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Fale com o CRO-MT - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Fale com o CRO-MT - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />
      <div style={{ height: "60vh", width: "100%" }}>
        <GoogleMapReact bootstrapURLKeys={{ key: defaultPropsMaps.key }} defaultCenter={defaultPropsMaps.center} defaultZoom={defaultPropsMaps.zoom}>
          <AnyReactComponent
            lat={defaultPropsMaps.center.lat}
            lng={defaultPropsMaps.center.lng}
            text={
              <img
                alt="Nosso Endereço"
                src={marker}
                style={{
                  width: 40,
                  height: "auto",
                }}
              />
            }
          />
        </GoogleMapReact>
      </div>
      <Main id="main">
        <Container>
          <Content>
            <Title title="Contato" />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Fale conosco</BoxTitle>
                  <BoxContent>
                    <BoxLists>
                      <BoxList>
                        <BoxItem>
                          <Clock width={22.81} height={22} fillColor="#3C3535" />
                          {config.business_hours}
                        </BoxItem>

                        <BoxItem>
                          <Email width={22} height={22.64} fillColor="#3C3535" />
                          <span>{config.emails}</span>
                        </BoxItem>

                        <BoxItem>
                          <Phone width={20} height={24.53} fillColor="#3C3535" />
                          <span>{config.phones}</span>
                        </BoxItem>
                      </BoxList>
                    </BoxLists>
                  </BoxContent>
                </Box>

                <AsideButton to="/delegacias">DELEGACIAS REGIONAIS</AsideButton>

                <Box>
                  <BoxTitle>Sede</BoxTitle>
                  <BoxContent>
                    <BoxLists>
                      <BoxList>
                        <a href={config.google_maps_street_view} target="_blank" rel="noopener noreferrer">
                          <BoxItem>
                            <Marker width={35} height={27.43} fillColor="#3C3535" />
                            {config.address}
                          </BoxItem>
                        </a>
                      </BoxList>
                    </BoxLists>
                  </BoxContent>
                </Box>
              </Aside>

              <ContentBody>
                <ContentBox>
                  <Form autoComplete="off" onSubmit={handleSave}>
                    <BorderInput style={{ top: borderInputPosition }}></BorderInput>

                    <GroupInput className={activeInput === "name" ? `active` : ""}>
                      <InputLabel for="name" required>
                        Seu Nome
                      </InputLabel>
                      <Input id="name" type="text" value={name} required onFocus={() => setFocus("name")} onChange={event => setName(event.target.value)} />
                    </GroupInput>

                    <GroupInput className={activeInput === "telephone" ? `active` : ""}>
                      <InputLabel for="telephone" required>
                        Telefone
                      </InputLabel>
                      <Input
                        id="telephone"
                        value={telephone}
                        required
                        type="text"
                        onFocus={() => setFocus("telephone")}
                        onChange={event => setTelephone(event.target.value)}
                        onKeyUp={event => setTelephone(maskTelephone89Digitos(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "email" ? `active` : ""}>
                      <InputLabel for="email">E-mail</InputLabel>
                      <Input
                        id="email"
                        type="email"
                        onFocus={() => setFocus("email")}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        style={{ textTransform: "none" }}
                      />
                    </GroupInput>

                    <GroupInput className={message === "message" ? `active` : ""}>
                      <InputLabel for="description" required>
                        Mensagem
                      </InputLabel>
                      <Textarea
                        id="message"
                        required
                        type="text"
                        onFocus={() => setFocus("message")}
                        value={message}
                        onChange={event => setMessage(event.target.value)}></Textarea>
                    </GroupInput>

                    <FormButton disabled={loading ? true : false}>{loading ? "Enviando ..." : "Enviar"}</FormButton>

                    {/*<Recaptcha ref={ref => (recaptcha = ref)} sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" onResolved={onResolvedRecaptcha} />*/}
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" />
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Contact),
);
