import Axios from "axios";

const INITIAL_STATE = {
  schedule: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        id: null,
        name: null,
        address: null,
        city: null,
        start_date: null,
        end_date: null,
        place: null,
        summary: null,
        image_path: null,
        is_active: null,
        schedule_type_id: null,
        uri: null,
        type: { color: null, name: null },
      },
    ],
  },
};

export const updateSchedule = (schedule) => ({
  type: "SET_SCHEDULE",
  payload: schedule,
});

export const getSchedule = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then((resp) => {
      dispatch(updateSchedule(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SCHEDULE":
      return { ...state, schedule: action.payload };
    default:
      return state;
  }
};
