import Axios from "axios";

const INITIAL_STATE = {
  presidents: {
    total: "",
    perPage: "",
    page: "",
    lastPage: "",
    data: [
      {
        id: "",
        name: "",
        start_date: "",
        end_date: "",
        image_path: "",
        is_active: null,
        created_by: null,
        updated_by: null,
        created_at: "",
        updated_at: null,
      },
    ],
  },
};

export const updatePresidents = param => ({
  type: "SET_PRESIDENTS",
  payload: param,
});

export const getPresidents = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updatePresidents(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PRESIDENTS":
      return { ...state, presidents: action.payload };
    default:
      return state;
  }
};
