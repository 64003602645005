import React from "react";

export default function Marker({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 19.872 27.435">
      <path
        d="M80.509,0a9.947,9.947,0,0,0-9.936,9.936c0,6.8,8.892,16.781,9.27,17.2a.9.9,0,0,0,1.331,0c.379-.422,9.27-10.4,9.27-17.2A9.947,9.947,0,0,0,80.509,0Zm0,14.935a5,5,0,1,1,5-5A5,5,0,0,1,80.509,14.935Z"
        transform="translate(-70.573)"
        fill={fillColor}
      />
    </svg>
  );
}
