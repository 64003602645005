import styled from "styled-components";

export const Container = styled.div``;

export const Figure = styled.figure`
  display: block;
  height: auto;
  max-width: 100%;
  overflow: hidden;

  transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;

  a {
    line-height: 0;
    font-size: 0;
  }
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
`;
