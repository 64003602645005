import React from "react";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getFiles } from "./../../store/files.reducers";
import { handlePaginateChange, handleGetPaginate } from "./../../services/functions";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import DocFile from "./../../assets/icons/doc-file";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemFigureText,
  ListItemContent,
  ListItemTitle,
} from "./styles";

const PATH = "/documentos-necessarios/";

const frontload = async props => {
  await props.getFiles(`${process.env.REACT_APP_API}/files`, {
    headers: { pagenumber: props.match.params.page ?? 1, pagelimit: 10 },
  });
  return;
};

function Files() {
  let { filesReducers: files } = useSelector(state => state);
  const paginate = files.files;
  files = files.files.data;

  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>
          Documentos necessários - {process.env.REACT_APP_TITLE} {paginate.page ? `- Pág. ${paginate.page}` : ""}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Documentos necessários - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta name="title" content={`Documentos necessários - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Documentos Necessários`} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>
              <ContentBody>
                {files.map(item => (
                  <ListItem key={item.id}>
                    <ListItemContent>
                      <a href={item.file_path} target="_blank" rel="noopener noreferrer">
                        <ListItemTitle>{item.name}</ListItemTitle>
                      </a>
                    </ListItemContent>

                    <ListItemFigure title={item.name} alt={item.name} href={item.file_path} target="_blank">
                      <DocFile width={43.93} height={53.28} fillColor="#FFFFFF" />
                      <ListItemFigureText>BAIXAR</ListItemFigureText>
                    </ListItemFigure>
                  </ListItem>
                ))}

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    files: state.filesReducers.files,
  };
};
const mapDispatchToProps = {
  getBanner,
  getFiles,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Files),
);
