import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { Helmet } from "react-helmet";

/* import store */
import { getBanner } from "./../../../store/banner.reducers";

/* import services */
import api from "./../../../services/api";
import upload from "./../../../services/s3";

/* import utils */
import { createPreviewImage, compress } from "./../../../util/thumbnail";

/* import functions */
import { maskTelephone89Digitos, maskCep } from "./../../../services/functions";

/* import components */
import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import MainServices from "./../../../components/MainServices";

/* import icons */
import inbox from "./../../../assets/icons/inbox.svg";

/* import styles */
import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterSelect,
  FilterButton,
  ContentBody,
  ContentMain,
  ContentBox,
  Form,
  GroupInput,
  InputLabel,
  Input,
  Textarea,
  Select,
  BorderInput,
  FormButton,
  Thumbnail,
  ThumbnailIcon,
  ThumbnailInput,
} from "./styles";

/* */
const frontload = async props => {
  return;
};

function Create() {
  const history = useHistory();
  const [activeInput, setActiveInput] = useState("partner_segment_id");
  const [borderInputPosition, setBorderInputPosition] = useState(0);

  const [filterTerm, setFilterTerm] = useState("");
  const [filterSegment, setFilterSegment] = useState("");
  const [segments, setSegments] = useState([]);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [address_number, setAddress_number] = useState("");
  const [address_cep, setAddress_cep] = useState("");
  const [address_city, setAddress_city] = useState("");
  const [address_state, setAddress_state] = useState("");
  const [address_neighborhood, setAddress_neighborhood] = useState("");
  const [phones, setPhones] = useState("");
  const [site, setSite] = useState("");
  const [benefits, setBenefits] = useState("");
  const [instagram, setInstagram] = useState("");
  const [partner_segment_id, setPartner_segment_id] = useState("");

  const [image_path, setImage_path] = useState("");
  const [compressedImages, setCompressedImages] = useState([]);

  //set page found
  global.redirectTo404 = false;
  global.page404 = false;
  async function setFocus(index) {
    await setActiveInput(index);
    handleScrollForm();
  }

  function handleScrollForm() {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`.active`)) {
        const offset = global.window.document.querySelector(`.active`).offsetTop;
        setBorderInputPosition(offset - 18);
      }
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    setLoading(true);

    const client_id = process.env.REACT_APP_CLIENT_ID;
    const site_id = process.env.REACT_APP_SITE_ID;

    const images = await upload(client_id, site_id, compressedImages);
    let image;

    if (images && images[0].fileUrl) {
      image = images[0].fileUrl;
    } else {
      image = "";
    }

    try {
      await api.post("/partners", {
        name,
        address,
        address_number,
        address_cep,
        address_city,
        address_state,
        address_neighborhood,
        phones,
        site,
        benefits,
        partner_segment_id,
        image_path: image,
        instagram,
      });

      setLoading(false);

      alert("Recebemos sua solicitação de parceria, breve o nosso sertor de Parcerias vai entrar em contato.");
      document.location.reload();
    } catch (error) {
      console.log(error);
      alert("Erro ao tentar enviar solicitação de parceria. Tente novamente!");
      setLoading(false);
    }
  };

  /* effect load segments  */
  useEffect(() => {
    async function loadSegments() {
      const { data } = await api.get("/partners/segments");
      setSegments(data);
    }
    loadSegments();
  }, []);

  useEffect(() => {
    async function compressImage() {
      setCompressedImages(await compress([image_path]));
    }
    compressImage();
  }, [image_path]);

  return (
    <>
      <Helmet>
        <title>Seja um dos nosso parceiros - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Seja um dos nosso parceiros - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Seja um dos nosso parceiros - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title="Seja nosso parceiro" />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Filtro de Parceiros</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por nome, endereço, benefícios…</FilterLabel>
                    <FilterInput type="text" value={filterTerm} onChange={event => setFilterTerm(event.target.value)} />
                    <FilterLabel>Segmento</FilterLabel>
                    <FilterSelect type="text" value={filterSegment} onChange={event => setFilterSegment(event.target.value)}>
                      <option value=""></option>
                      {segments.map(segment => (
                        <option key={segment.id} value={segment.id}>
                          {segment.name}
                        </option>
                      ))}
                    </FilterSelect>
                    <FilterButton
                      onClick={() => {
                        history.push(`/parceiros/?filtro=${filterTerm}&segmento=${filterSegment}`);
                      }}>
                      Pesquisar
                    </FilterButton>
                  </BoxContent>
                </Box>

                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <Form autoComplete="off" onSubmit={handleSave}>
                    <BorderInput style={{ top: borderInputPosition }}></BorderInput>

                    <GroupInput className={activeInput === "partner_segment_id" ? `active` : ""}>
                      <InputLabel for="partner_segment_id" required>
                        Segmento
                      </InputLabel>
                      <Select
                        id="partner_segment_id"
                        value={partner_segment_id}
                        required
                        onFocus={() => setFocus("partner_segment_id")}
                        onChange={event => setPartner_segment_id(event.target.value)}>
                        <option value="">Selecione</option>
                        {segments.map(segment => (
                          <option key={segment.id} value={segment.id}>
                            {segment.name}
                          </option>
                        ))}
                      </Select>
                    </GroupInput>

                    <GroupInput className={activeInput === "name" ? `active` : ""}>
                      <InputLabel for="name" required>
                        Nome Fantasia
                      </InputLabel>
                      <Input id="name" type="text" value={name} required onFocus={() => setFocus("name")} onChange={event => setName(event.target.value)} />
                    </GroupInput>

                    <GroupInput className={activeInput === "phones" ? `active` : ""}>
                      <InputLabel for="phones" required>
                        Telefone
                      </InputLabel>
                      <Input
                        id="phones"
                        value={phones}
                        required
                        type="text"
                        onFocus={() => setFocus("phones")}
                        onChange={event => setPhones(event.target.value)}
                        onKeyUp={event => setPhones(maskTelephone89Digitos(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "site" ? `active` : ""}>
                      <InputLabel for="site">Site</InputLabel>
                      <Input id="site" type="text" onFocus={() => setFocus("site")} value={site} onChange={event => setSite(event.target.value)} />
                    </GroupInput>

                    <GroupInput className={activeInput === "instagram" ? `active` : ""}>
                      <InputLabel for="instagram">Instagram</InputLabel>
                      <Input
                        id="instagram"
                        type="text"
                        onFocus={() => setFocus("instagram")}
                        value={instagram}
                        onChange={event => setInstagram(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_cep" ? `active` : ""}>
                      <InputLabel for="address_cep">CEP</InputLabel>
                      <Input
                        id="address_cep"
                        type="text"
                        onFocus={() => setFocus("address_cep")}
                        value={address_cep}
                        onChange={event => setAddress_cep(event.target.value)}
                        onKeyUp={event => setAddress_cep(maskCep(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address" ? `active` : ""}>
                      <InputLabel for="address" required>
                        Endereço
                      </InputLabel>
                      <Input
                        id="address"
                        type="text"
                        required
                        onFocus={() => setFocus("address")}
                        value={address}
                        onChange={event => setAddress(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_number" ? `active` : ""}>
                      <InputLabel for="address_number">Número</InputLabel>
                      <Input
                        id="address_number"
                        type="text"
                        onFocus={() => setFocus("address_number")}
                        value={address_number}
                        onChange={event => setAddress_number(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_neighborhood" ? `active` : ""}>
                      <InputLabel for="address_neighborhood" required>
                        Bairro
                      </InputLabel>
                      <Input
                        id="address_neighborhood"
                        required
                        type="text"
                        onFocus={() => setFocus("address_neighborhood")}
                        value={address_neighborhood}
                        onChange={event => setAddress_neighborhood(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_city" ? `active` : ""}>
                      <InputLabel for="address_city" required>
                        Cidade
                      </InputLabel>
                      <Input
                        id="address_city"
                        required
                        type="text"
                        onFocus={() => setFocus("address_city")}
                        value={address_city}
                        onChange={event => setAddress_city(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "address_state" ? `active` : ""}>
                      <InputLabel for="address_state" required>
                        Estado (UF)
                      </InputLabel>
                      <Input
                        id="address_state"
                        required
                        type="text"
                        maxLength={2}
                        onFocus={() => setFocus("address_state")}
                        value={address_state}
                        onChange={event => setAddress_state(event.target.value)}
                        style={{ textTransform: "uppercase" }}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "benefits" ? `active` : ""}>
                      <InputLabel for="benefits" required>
                        Vantagens oferecidas
                      </InputLabel>
                      <Textarea
                        id="benefits"
                        required
                        type="text"
                        onFocus={() => setFocus("benefits")}
                        value={benefits}
                        onChange={event => setBenefits(event.target.value)}></Textarea>
                    </GroupInput>

                    <GroupInput>
                      <InputLabel for="benefits">CLIQUE PARA ANEXAR SUA LOGOMARCA</InputLabel>
                      <Thumbnail
                        style={{
                          backgroundImage: `url("${createPreviewImage(image_path)}")`,
                        }}>
                        <ThumbnailInput id="" accept="image/x-png,image/gif,image/jpeg" type="file" onChange={event => setImage_path(event.target.files[0])} />
                        <ThumbnailIcon style={{ display: image_path ? "none" : "" }} src={inbox} alt="Selecione uma imagem" />
                      </Thumbnail>
                    </GroupInput>

                    <FormButton disabled={loading ? true : false}>{loading ? "Enviando ..." : "Enviar"}</FormButton>
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Create),
);
