import Axios from "axios";

const INITIAL_STATE = {
  banner: [
    {
      id: null,
      uri: "",
      target: null,
      title: null,
      position: null,
      is_active: null,
      image_path: null,
    },
  ],
};

export const updateBanner = (banner) => ({
  type: "SET_BANNER",
  payload: banner,
});

export const getBanner = (url, banner) => (dispatch, getState) => {
  return Axios.get(url, banner)
    .then((resp) => {
      dispatch(updateBanner(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_BANNER":
      return { ...state, banner: action.payload };
    default:
      return state;
  }
};
