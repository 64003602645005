import Axios from "axios";
import { useHistory } from "react-router-dom";

const INITIAL_STATE = {
  eventDetails: {
    id: null,
    title: null,
    summary: null,
    image_path: null,
    is_active: null,
    uri: null,
    date: null,
    images: [],
  },
};

export const updateEventDetails = param => ({
  type: "SET_EVENT_DETAILS",
  payload: param,
});

export const getEventDetails = (url, config, history) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateEventDetails(resp.data));
    })
    .catch(function (error) {
      history.push("/404");
    });
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_EVENT_DETAILS":
      return { ...state, eventDetails: action.payload };
    default:
      return state;
  }
};
