import React from "react";

export default function Speaker({ width, height, fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 35.958 40.885">
      <g transform="translate(-30.849)">
        <g transform="translate(30.849 1.525)">
          <g transform="translate(0)">
            <path
              d="M64.078,33.7,50.53,20.148a3.593,3.593,0,0,0-5.518,4.55l-.019.094a25.066,25.066,0,0,1-6.877,12.85L31.9,43.857a3.59,3.59,0,0,0,0,5.08l3.387,3.387a3.59,3.59,0,0,0,5.08,0l.847-.847L47.144,57.4a3.592,3.592,0,1,0,5.08-5.08l-2.54-2.54.847-.847a3.59,3.59,0,0,0,0-5.08l-.575-.575a25.07,25.07,0,0,1,9.479-4.048l.1-.019a3.6,3.6,0,0,0,4.549-.438h0A3.6,3.6,0,0,0,64.078,33.7ZM38.676,50.63a1.2,1.2,0,0,1-1.694,0L33.6,47.244a1.2,1.2,0,0,1,0-1.693l5.927-5.927L44.6,44.7ZM50.53,54.017a1.2,1.2,0,1,1-1.693,1.693L42.91,49.784,44.6,48.09ZM46.3,46.4c.36-.36.9-.911,1.749-1.638l.791.791a1.2,1.2,0,0,1,0,1.693l-.847.847Zm.051-3.336-5.182-5.182A27.422,27.422,0,0,0,47,26.774L57.452,37.231A27.421,27.421,0,0,0,46.348,43.061Zm16.037-5.978a1.2,1.2,0,0,1-1.693,0L47.144,23.535a1.2,1.2,0,0,1,1.694-1.693L62.385,35.389A1.2,1.2,0,0,1,62.385,37.083Z"
              transform="translate(-30.849 -19.098)"
              fill={fillColor}
            />
          </g>
        </g>
        <g transform="translate(36.632 25.933)">
          <g transform="translate(0)">
            <path
              d="M107,325.113a1.2,1.2,0,0,0-1.693,0l-1.693,1.693a1.2,1.2,0,1,0,1.693,1.693L107,326.806A1.2,1.2,0,0,0,107,325.113Z"
              transform="translate(-103.266 -324.762)"
              fill={fillColor}
            />
          </g>
        </g>
        <g transform="translate(54.833)">
          <path
            d="M332.391,0a1.2,1.2,0,0,0-1.2,1.2V3.592a1.2,1.2,0,0,0,2.395,0V1.2A1.2,1.2,0,0,0,332.391,0Z"
            transform="translate(-331.194)"
            fill={fillColor}
          />
        </g>
        <g transform="translate(62.018 9.58)">
          <g transform="translate(0)">
            <path
              d="M424.761,119.966h-2.395a1.2,1.2,0,1,0,0,2.395h2.395a1.2,1.2,0,1,0,0-2.395Z"
              transform="translate(-421.169 -119.966)"
              fill={fillColor}
            />
          </g>
        </g>
        <g transform="translate(59.623 2.395)">
          <path
            d="M395.617,30.342a1.2,1.2,0,0,0-1.693,0l-2.395,2.395a1.2,1.2,0,1,0,1.693,1.693l2.395-2.395A1.2,1.2,0,0,0,395.617,30.342Z"
            transform="translate(-391.178 -29.991)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
}
