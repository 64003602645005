import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Alert from "sweetalert";
import * as yup from "yup";
import Header from "../../components/Header";
import Title from "../../components/Title";
import api from "../../services/api";

import {
  Container,
  Content,
  ContentBody,
  ContentMain,
  Main,
  ContentBox,
  AvisoBox,
  AvisoTitle,
  AvisoContent,
  Form,
  FieldContainer,
  Label,
  InputText,
  Enviar,
  EnviarContainer,
  TextArea,
} from "./styles";

const formSchema = yup.object().shape({
  name: yup.string().required("Por favor, informe seu nome completo"),
  email: yup.string().email("Deve ser um email valido").required("Informe seu e-mail").typeError("Deve ser um email valido"),
  registration_number: yup.string().required("Informe seu número de registro CRO-MT"),
  summary: yup.string().required("Por favor, preencha o campo [Justifique sua ausência]"),
});

function JustifyVote() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [summary, setSummary] = useState("");
  const [registration_number, setRegistration_number] = useState("");
  const [path, setPath] = useState("");

  const [loading, setLoading] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const handleSubmit = async () => {
    const data = { name, email, registration_number, file_path: path, summary };

    await formSchema
      .validate(data)
      .then(() => {
        try {
          setLoading(true);
          api
            .post("/justify-vote", data)
            .then(() => {
              setName("");
              setEmail("");
              setRegistration_number("");
              setPath("");
              setSummary("");
              Alert("Sucesso", "Justificativa enviada com sucesso!", "success");
              setLoading(false);
            })
            .catch(e => {
              Alert("Erro", `Verifique seus dados e tente novamente`, "error");
              setLoading(false);
            });
        } catch (err) {
          Alert("Erro", `${err.errors}`, "error");
          setLoading(false);
        }
      })
      .catch(err => {
        Alert("Erro", `${err.errors}`, "error");
        setLoading(false);
      });
  };

  async function attachFile(event) {
    const input = event.target;
    const files = input.files;

    if (files && files.length > 0) {
      setUploading(true);

      const formData = new FormData();
      formData.append("file", files[0]);

      const { data } = await api.post(`/upload`, formData);

      setPath(data.url);

      setUploading(false);
    }
  }

  return (
    <div>
      <Helmet>
        <title>Justifique sua ausência - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Justifique sua ausência - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Justifique sua ausência - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Main id="main">
        <Container>
          <Content>
            <Title title="Justifique sua ausência" />
            <ContentMain>
              <ContentBody>
                <ContentBox>
                  <AvisoBox>
                    <AvisoTitle>Eleição realizada em 06 de outubro de 2023.</AvisoTitle>
                    <AvisoContent>Use o formulário a seguir para justificar sua ausência. Preencha corretamente os campos.</AvisoContent>
                  </AvisoBox>
                  <Form>
                    <FieldContainer>
                      <Label>Seu nome completo</Label>
                      <InputText value={name} onChange={e => setName(e.target.value)} required={true} />
                    </FieldContainer>
                    <FieldContainer>
                      <Label>Número de inscrição no CRO-MT</Label>
                      <InputText value={registration_number} onChange={e => setRegistration_number(e.target.value)} required={true} />
                    </FieldContainer>
                    <FieldContainer>
                      <Label>E-mail</Label>
                      <InputText value={email} onChange={e => setEmail(e.target.value)} required={true} />
                    </FieldContainer>
                    <FieldContainer>
                      <Label>Justifique sua ausência</Label>
                      <TextArea rows={5} value={summary} onChange={e => setSummary(e.target.value)} required={true}>
                        {summary}
                      </TextArea>
                    </FieldContainer>
                    <FieldContainer>
                      <Label>Arquivo</Label>
                      <InputText type="file" onChange={attachFile} />
                    </FieldContainer>
                    <EnviarContainer>
                      {uploading ? (
                        <Enviar disabled={true}>Enviando arquivo...</Enviar>
                      ) : (
                        <Enviar disabled={loading} onClick={handleSubmit}>
                          {loading ? "Enviando justificativa..." : "Enviar"}
                        </Enviar>
                      )}
                    </EnviarContainer>
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
      </Main>
      <Header />
    </div>
  );
}

export default JustifyVote;
