import styled from "styled-components";

import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 510px;
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1366px) {
    width: 400px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Figure = styled.figure`
  position: relative;
  width: 471px;
  height: 585px;
  overflow: hidden;

  @media (max-width: 1366px) {
    width: 361px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;

  filter: ${props => (props.theme.title === "dark" ? `grayscale(100%) contrast(120%)` : `unset`)};
`;

export const Footer = styled(Link)`
  font-family: "NunitoSans-Bold";
  font-size: 20px;
  line-height: 27px;
  color: ${props => props.theme.colors.text};
  text-transform: uppercase;
  text-decoration: underline;

  padding: 24px 0 10px 0;
`;
