import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { getDateByTimeZoneCba } from "./../../services/functions";

import { Container, TitleBox, Title, Button, Content, Box, BoxHeader, BoxContent, BoxDate, BoxFooter, Boxes } from "./styles";

import Left from "./../../assets/icons/arrows/left";
import Right from "./../../assets/icons/arrows/right";
import Cromt from "./../../assets/icons/cromt";

let timerBox;
export default function ScheduleBox() {
  let { scheduleReducers: events } = useSelector(state => state);
  events = events.schedule.data;

  function handleMoveEvents(left) {
    if (typeof global.window !== "undefined") {
      if (document.querySelector("#events")) {
        if (left) document.querySelector("#events").scrollBy(-300, 0);
        else document.querySelector("#events").scrollBy(300, 0);
      }
    }
  }

  return (
    <Container>
      <TitleBox>
        <Button onClick={() => handleMoveEvents(true)}>
          <Left width={14.69} height={25} fillColor="#FFFFFF" />
        </Button>
        <Title>Nossos Próximos Eventos</Title>
        <Button onClick={() => handleMoveEvents(false)}>
          <Right width={14.69} height={25} fillColor="#FFFFFF" />
        </Button>
      </TitleBox>

      <Content id="events">
        <Boxes>
          {events.map(({ id, name, uri, type, start_date }) => (
            <Box bg={type.color} key={id}>
              <BoxHeader>
                <Cromt width={45.5} height={17.28} fillColor="#FFFFFF" />
                {type.name}
              </BoxHeader>
              <BoxContent>{name}</BoxContent>
              <BoxDate>{getDateByTimeZoneCba(start_date, "dd'/'MM'/'yyyy 'às' HH':'mm")}</BoxDate>
              <BoxFooter to={`/agenda/detalhes/${uri}`} title={name}>
                Saiba mais
              </BoxFooter>
            </Box>
          ))}
        </Boxes>
      </Content>
    </Container>
  );
}
