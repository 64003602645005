import React from "react";

export default function DocFile({ width, height, fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 43.928 53.282">
      <g transform="translate(-44.944)">
        <g transform="translate(44.944)">
          <g transform="translate(0)">
            <path
              d="M88.554,13.2,75.67.319A1.088,1.088,0,0,0,74.9,0H50.229a5.291,5.291,0,0,0-5.285,5.285V48a5.291,5.291,0,0,0,5.285,5.285H83.587A5.291,5.291,0,0,0,88.872,48V13.971A1.088,1.088,0,0,0,88.554,13.2ZM75.988,3.713l9.171,9.171H79.1a3.11,3.11,0,0,1-3.11-3.11ZM86.7,48a3.113,3.113,0,0,1-3.11,3.11H50.229A3.114,3.114,0,0,1,47.119,48V5.285a3.114,3.114,0,0,1,3.11-3.11H73.814v7.6A5.284,5.284,0,0,0,79.1,15.059h7.6V48Z"
              transform="translate(-44.944)"
              fill={fillColor}
            />
          </g>
        </g>
        <g transform="translate(67.321 36.579)">
          <path
            d="M261.539,351.492h-.487a1.087,1.087,0,0,0,0,2.175h.487a1.087,1.087,0,0,0,0-2.175Z"
            transform="translate(-259.965 -351.492)"
            fill={fillColor}
          />
        </g>
        <g transform="translate(53.241 36.579)">
          <path
            d="M136.318,351.492H125.756a1.087,1.087,0,1,0,0,2.175h10.562a1.087,1.087,0,0,0,0-2.175Z"
            transform="translate(-124.669 -351.492)"
            fill={fillColor}
          />
        </g>
        <g transform="translate(53.241 31.61)">
          <g transform="translate(0)">
            <path
              d="M150.917,303.747h-25.16a1.087,1.087,0,0,0,0,2.175h25.16a1.087,1.087,0,0,0,0-2.175Z"
              transform="translate(-124.67 -303.747)"
              fill={fillColor}
            />
          </g>
        </g>
        <g transform="translate(53.241 26.641)">
          <g transform="translate(0)">
            <path
              d="M150.917,256h-25.16a1.087,1.087,0,1,0,0,2.175h25.16a1.087,1.087,0,0,0,0-2.175Z"
              transform="translate(-124.67 -256)"
              fill={fillColor}
            />
          </g>
        </g>
        <g transform="translate(53.241 21.672)">
          <g transform="translate(0)">
            <path
              d="M150.917,208.255h-25.16a1.087,1.087,0,1,0,0,2.175h25.16a1.087,1.087,0,0,0,0-2.175Z"
              transform="translate(-124.67 -208.255)"
              fill={fillColor}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
