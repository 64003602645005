import React from "react";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getPresidents } from "./../../store/presidents.reducers";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";

import { getDate, getDateByTimeZoneCba } from "./../../services/functions";

import { Main, Container, Content, ContentBody, ContentMain, Items, Item, ItemFigure, ItemImage, ItemTitle, ItemSubTitle } from "./styles";

const frontload = async props => {
  await props.getPresidents(`${process.env.REACT_APP_API}/presidents`, {});
  return;
};

function Presidents() {
  let { presidentsReducers: presidents } = useSelector(state => state);
  presidents = presidents.presidents.data;

  return (
    <>
      <Helmet>
        <title>Galeria de Presidentes do CRO-MT</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Galeria de Presidentes do CRO-MT`} />
        <meta name="title" content={`Galeria de Presidentes do CRO-Mt`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Galeria de Presidentes`} />
            <ContentMain>
              <ContentBody>
                <Items>
                  {presidents.map(president => (
                    <Item key={president.id}>
                      <ItemFigure>
                        <ItemImage src={president.image_path} title={president.name} alt={president.name} />
                      </ItemFigure>
                      <ItemTitle>{president.name}</ItemTitle>
                      <ItemSubTitle>
                        {getDateByTimeZoneCba(president.start_date, "yyyy")} à {getDateByTimeZoneCba(president.end_date, "yyyy")}
                      </ItemSubTitle>
                    </Item>
                  ))}
                </Items>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    presidents: state.presidentsReducers.presidents,
  };
};
const mapDispatchToProps = {
  getBanner,
  getPresidents,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Presidents),
);
