import styled from "styled-components";

export const Container = styled.footer`
  background-color: ${props => props.theme.colors.secundary};
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    .logo {
      width: 450px;
      height: auto;
    }
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    .logo {
      width: 350px;
      height: auto;
    }
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .logo {
      width: 350px;
      height: auto;
    }
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Nav = styled.nav`
  padding: 0 140px;

  @media (max-width: 1366px) {
    padding: 0px 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Items = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Item = styled.li`
  flex: 1;
  padding: 40px;

  a {
    font-family: "NunitoSans-ExtraLight";
    font-size: 18px;
    line-height: 26px;
    color: #ffffff;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    svg {
      margin-right: 15px;
    }

    span {
      letter-spacing: 1.5px;
    }
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    padding: 8px;

    a {
      margin: 19px;
    }
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    a {
      font-size: 16px;
      line-height: 21px;
    }
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    a {
      font-size: 14px;
      line-height: 21px;

      span {
        letter-spacing: 1px;
      }
    }
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto 80px;
  max-width: 1000px;
  width: 100%;

  & > div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  a {
    padding: 12px;

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */

    margin: 20px 0;

    svg {
      height: 30px;
    }
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Copy = styled.section`
  padding: 17.79px 140px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${props => props.theme.colors.tertiary};

  @media (max-width: 1366px) {
    padding: 17.79px 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Description = styled.p`
  font-family: "NunitoSans-ExtraLight";
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 1.5px;
  color: #ffffff;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;

    text-align: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
