import styled from "styled-components";

export const Container = styled.div`
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  margin-bottom: 50px;
  width: 100%;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    width: 100%;
    align-self: center;
    display: none;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Title = styled.div`
  background-color: #eaeaea;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #3c3535;
  font-family: "NunitoSans-ExtraBold";
  font-size: 19px;
  line-height: 35px;
  text-transform: uppercase;

  padding: 22px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 30px;
    padding: 2px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  padding: 18px;
`;

export const Lists = styled.nav``;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

export const Item = styled.li`
  font-family: "NunitoSans-SemiBold";
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #3c3535;

  margin-bottom: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  flex: 1;
  border-bottom: 0.5px solid #e6e6e6;
  &:hover {
    color: #d9473d;
  }
`;
