import Axios from "axios";

const INITIAL_STATE = {
  videos: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        id: null,
        title: null,
        image_path: null,
        is_active: null,
        uri: null,
        date: null,
      },
    ],
  },
};

export const updateVideos = param => ({
  type: "SET_VIDEOS",
  payload: param,
});

export const getVideos = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateVideos(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_VIDEOS":
      return { ...state, videos: action.payload };
    default:
      return state;
  }
};
