import React from "react";
import { Helmet } from "react-helmet";

import { Container, Content, Label, H1, Comment, Button } from "./styles";

import Android from "./android";

export default function NotFound() {
  return (
    <>
      <Helmet>
        <title>Página não encontrada - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Página não encontrada - ${process.env.REACT_APP_TITLE} `} />
        <meta name="title" content={`Página não encontrada - ${process.env.REACT_APP_TITLE} `} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Container id="home">
        <Content>
          <Label>404</Label>
          <H1>Esta página não existe mais.</H1>
          <Comment>
            Não conseguimos encontrar a página que você estava buscando.
            <br />
            Felizmente temos inúmeros conteúdos que são de seu interesse. :)
          </Comment>
          <Button to="/">Voltar</Button>
        </Content>
        <Android />
      </Container>
    </>
  );
}
