import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.section`
  background-color: ${props => props.theme.colors.backgroundPrimary};

  margin: 0 140px;
  margin-top: 0px;

  @media (max-width: 1366px) {
    margin-top: 0px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin: 0 25px;
    margin-top: 0px !important;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    display: none;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const TitleBox = styled.header`
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  flex-direction: row;
  padding: 11px;
`;

export const Title = styled.h2`
  color: #ffffff;
  font-size: 43px;
  line-height: 58px;
  text-transform: uppercase;
  font-family: "NunitoSans-Bold";

  flex: 1;
  text-align: center;

  @media (max-width: 1366px) {
    font-size: 32px;
    line-height: 47px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 22px;
    line-height: 37px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Button = styled.button`
  background-color: transparent;
  border: 0;
  margin: 0 9px;
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    opacity: 0.5;
  }
`;

export const Content = styled.article`
  max-width: 100%;
  min-width: 100%;

  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }

  /* width */
  &::-webkit-scrollbar {
    height: 7px;
    cursor: pointer;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #861811;
    border-radius: 0px;
    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #d9473d;
  }
`;

export const Boxes = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;

  transition: all 0.7s ease-in-out;

  padding: 15px;
`;

export const Box = styled.div`
  background-color: ${props => props.bg};
  padding: 30px 9px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;

  width: 240px;
  margin: 15px;
`;

export const BoxHeader = styled.h4`
  font-family: "NunitoSans-Bold";
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #ffffff;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    padding: 0 10px;
  }

  @media (max-width: 1366px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxContent = styled.h2`
  font-family: "NunitoSans-ExtraBold";
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  color: #3c3535;
  flex: 1;
  margin: 20px 0px;
  margin-bottom: 0;

  @media (max-width: 1366px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxDate = styled.h2`
  font-family: "NunitoSans-ExtraBold";
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #3c3535;
  flex: 1;
  margin: 0px 0 40px 0px;

  @media (max-width: 1366px) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0 20px 0px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 12px;
    line-height: 18px;
    margin: 0px 0 10px 0px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxFooter = styled(Link)`
  font-family: "NunitoSans-ExtraBold";
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: underline;

  border: 0;
  background-color: transparent;

  cursor: pointer;
  align-self: center;

  @media (max-width: 1366px) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 12px;
    line-height: 18px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
