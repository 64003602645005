import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

#root{

  display: contents;

  #head, #header, #featured, #main, #footer{
    filter: ${props => (props.contrast ? "grayscale(100%)" : "grayscale(0%)")};
  }

}

`;
