import React from "react";

export default function Email({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 30.193 22.645">
      <path
        d="M27.362,59.882H2.831A2.834,2.834,0,0,0,0,62.713V79.7a2.834,2.834,0,0,0,2.831,2.831H27.362A2.834,2.834,0,0,0,30.193,79.7V62.713A2.834,2.834,0,0,0,27.362,59.882Zm0,1.887a.938.938,0,0,1,.362.073L15.1,72.786,2.469,61.842a.939.939,0,0,1,.362-.073Zm0,18.87H2.831a.944.944,0,0,1-.944-.944V63.836L14.478,74.748a.945.945,0,0,0,1.237,0L28.306,63.836V79.7A.944.944,0,0,1,27.362,80.639Z"
        transform="translate(0 -59.882)"
        fill={fillColor}
      />
    </svg>
  );
}
