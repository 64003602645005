import Axios from "axios";

const INITIAL_STATE = {
  adsDetails: {
    id: null,
    title: null,
    name: null,
    cro: null,
    tel: null,
    email: null,
    description: null,
    image_path: null,
    ads_type_id: null,
    type: { name: "" },
  },
};

export const updateAdsDetails = param => ({
  type: "SET_ADS_DETAILS",
  payload: param,
});

export const getAdsDetails = (url, config, history) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateAdsDetails(resp.data));
    })
    .catch(function (error) {
      history.push("/404");
    });
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_ADS_DETAILS":
      return { ...state, adsDetails: action.payload };
    default:
      return state;
  }
};
