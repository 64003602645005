import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

/* import store */
import { getBanner } from "./../../../store/banner.reducers";

/* import services */
import api from "./../../../services/api";

/* import functions */
import { maskInteger, maskChaveSeguranca, maskCpfCnpj } from "./../../../services/functions";

/* import components */
import Header from "./../../../components/Header";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import MainServices from "./../../../components/MainServices";

/* import styles */
import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ContentBox,
  ContentSummary,
  Form,
  GroupInput,
  InputLabel,
  Input,
  Select,
  BorderInput,
  FormButton,
  FormTitle,
} from "./styles";

const frontload = async props => {
  return;
};

function Auth() {
  const recaptchaRef = React.useRef();

  const history = useHistory();

  const [activeInput, setActiveInput] = useState("categoria");
  const [borderInputPosition, setBorderInputPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const [key, setKey] = useState("");
  const [cpf, setCpf] = useState("");

  const [is_error, setIs_error] = useState(false);

  async function setFocus(index) {
    await setActiveInput(index);
    handleScrollForm();
  }

  function handleScrollForm() {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`.active`)) {
        const offset = global.window.document.querySelector(`.active`).offsetTop;
        setBorderInputPosition(offset - 18);
      }
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    setLoading(true);
    //onResolvedRecaptcha();
    const token = await recaptchaRef.current.executeAsync();

    if (!token) {
      alert("Erro! Tente novamente");
      console.log("erro recaptcha: ", token);
      setLoading(false);
      return false;
    }
    onResolvedRecaptcha();
  };

  const onResolvedRecaptcha = async () => {
    try {
      const { data } = await api.get(`/courses/check/certificate/?key=${key}&cpf=${cpf}`, {});
      console.log(data);

      setLoading(false);
      setIs_error(false);

      history.push(`/emitir-certificados/${key}/${cpf}`);
    } catch (error) {
      console.log(error);
      setIs_error(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Autenticar Certificado - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Autenticar Certificado - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Autenticar Certificado - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title="Autenticar Certificado" />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <Form autoComplete="off" onSubmit={handleSave}>
                    <BorderInput style={{ top: borderInputPosition }}></BorderInput>
                    <GroupInput className={activeInput === "cpf" ? `active` : ""}>
                      <InputLabel for="cpf" required>
                        CPF *
                      </InputLabel>
                      <Input id="cpf" type="text" required onFocus={() => setFocus("cpf")} value={cpf} onChange={event => setCpf(maskCpfCnpj(event.target))} />
                    </GroupInput>

                    <GroupInput className={activeInput === "key" ? `active` : ""}>
                      <InputLabel for="key" required>
                        Código de Validação *
                      </InputLabel>
                      <Input
                        id="key"
                        type="text"
                        required
                        onFocus={() => setFocus("key")}
                        value={key}
                        onChange={event => setKey(maskChaveSeguranca(event.target))}
                        onKeyUp={event => setKey(maskChaveSeguranca(event.target))}
                        style={{ textTransform: "uppercase" }}
                      />
                    </GroupInput>

                    <FormButton disabled={loading ? true : false}>{loading ? "Autenticando ... " : "Autenticar"}</FormButton>

                    <div style={{ display: is_error === true ? "block" : "none" }}>
                      <GroupInput>
                        <FormTitle style={{ color: "red" }}>
                          Não foi possivel verificar a autenticidade. Não existe Certificado para a chave informada.
                        </FormTitle>
                      </GroupInput>
                    </div>

                    {/*<Recaptcha ref={ref => (recaptcha = ref)} sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" onResolved={onResolvedRecaptcha} />*/}
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" />
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Auth),
);
