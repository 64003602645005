import Axios from "axios";

const INITIAL_STATE = {
  nav: [
    {
      id: null,
      name: null,
      uri: "",
      target: null,
      title: null,
      position: null,
      is_active: null,
      has_sub_navigation: false,
      sub_navigations: [],
    },
  ],
};

export const updateNav = (nav) => ({
  type: "SET_NAV",
  payload: nav,
});

export const getNav = (url, nav) => (dispatch, getState) => {
  return Axios.get(url, nav)
    .then((resp) => {
      dispatch(updateNav(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_NAV":
      return { ...state, nav: action.payload };
    default:
      return state;
  }
};
