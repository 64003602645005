import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";

import api from "./../../services/api";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import MainServices from "./../../components/MainServices";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";

import { Main, Container, Content, Aside, ContentBody, ContentSummary, ContentMain, ContentBox } from "./styles";

const frontload = async props => {
  return;
};

function Pages() {
  const { uri } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    async function loadPage() {
      try {
        const { data } = await api.get(`/pages/uri/${uri}`, {});
        setTitle(data.title);
        setDescription(data.description);
        setContent(data.content);
      } catch (error) {}
    }

    loadPage();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta name="title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={title} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <ContentSummary
                    className="ck-content"
                    dangerouslySetInnerHTML={{
                      __html: content ?? "",
                    }}></ContentSummary>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Pages),
);
