import styled from "styled-components";

export const Container = styled.ul`
  position: absolute;
  left: 0;
  width: max-content;
  max-height: ${props => (props.showing ? "100vh" : `0px`)};
  top: 53px;
  background-color: rgba(134, 24, 17, 0.8);
  overflow: hidden;
  transition: 0.4s ease-in all;

  z-index: 9;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    max-height: ${props => (props.showing ? "100vh" : `0px`)};
    position: relative;
    top: auto;
    background-color: rgba(68, 6, 2, 0.4);
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Item = styled.li`
  font-size: 15pt;
  line-height: 21px;
  color: #ffffff;
  text-transform: initial;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: #861811;
  }

  a {
    padding: 15px 18px;
    display: block;
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    a {
      font-size: 12px;
      display: block;
      padding: 10px 18px;
    }
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
