import Axios from "axios";

const INITIAL_STATE = {
  events: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        id: null,
        title: null,
        summary: null,
        image_path: null,
        is_active: null,
        uri: null,
        date: null,
        images: [],
      },
    ],
  },
};

export const updateEvents = (events) => ({
  type: "SET_EVENTS",
  payload: events,
});

export const getEvents = (url, events) => (dispatch, getState) => {
  return Axios.get(url, events)
    .then((resp) => {
      dispatch(updateEvents(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_EVENTS":
      return { ...state, events: action.payload };
    default:
      return state;
  }
};
