import styled from "styled-components";
import { Link } from "react-router-dom";

export const Main = styled.main`
  position: relative;
`;

export const Container = styled.section`
  padding: 40px 140px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

  &#galleries {
    background-color: ${props => props.theme.colors.backgroundTertiary};
  }

  @media (max-width: 1366px) {
    padding: 40px 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    article.hide-1281 {
      display: none;
    }
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Services = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 45px;

  transition: all 0.4s ease-in-out;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    grid-template-columns: 1fr;
    width: 100%;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ServiceFigure = styled.figure`
  padding-bottom: 50px;
`;

export const ServiceImage = styled.img`
  transition: all 0.4s ease-in-out;

  width: 75px;

  @media (max-width: 1366px) {
    // width: 65%;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ServiceTitle = styled.h3`
  font-family: "NunitoSans-Bold";
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  /* padding-bottom: 60px; */
  padding-top: 25px;

  @media (max-width: 1366px) {
    // font-size: 22px;
    // line-height: 33px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    // font-size: 18px;
    // line-height: 29px;
    // padding-bottom: 45px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ServiceFooterA = styled.div`
  font-family: "NunitoSans-Bold";
  font-size: 20px;
  line-height: 41px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;

  border: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: ${props => props.theme.colors.boxPrimary};

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: calc(90% - 20px);

  overflow: hidden;
  height: 0px;

  transition: 0.4s ease-in-out;

  @media (max-width: 1366px) {
    font-size: 18px;
    line-height: 39px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    font-size: 16px;
    line-height: 37px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ServiceFooter = styled.div`
  font-family: "NunitoSans-Bold";
  font-size: 20px;
  line-height: 41px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: underline;

  border: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: ${props => props.theme.colors.boxPrimary};

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: calc(90% - 20px);

  overflow: hidden;
  height: 0px;

  transition: 0.4s ease-in-out;

  @media (max-width: 1366px) {
    font-size: 18px;
    line-height: 39px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    font-size: 16px;
    line-height: 37px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Service = styled(Link)`
  padding: 30px;
  background-color: #d9473d;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.4s ease-in-out;
  position: relative;

  &:hover {
    background-color: #861811;

    ${ServiceFooter} {
      height: 65px;
      width: 100%;
    }
    ${ServiceFooterA} {
      height: 65px;
      width: 100%;
    }
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */

    background-color: #861811;

    ${ServiceFooter} {
      height: 65px;
      width: 100%;
    }
    ${ServiceFooterA} {
      height: 65px;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ServiceA = styled.a`
  padding: 18px;
  background-color: #d9473d;
  border-radius: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  position: relative;

  &:hover {
    background-color: #861811;

    ${ServiceFooter} {
      height: 65px;
      width: 100%;
    }
    ${ServiceFooterA} {
      height: 65px;
      width: 100%;
    }
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */

    background-color: #861811;

    ${ServiceFooter} {
      height: 65px;
      width: 100%;
    }
    ${ServiceFooterA} {
      height: 65px;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const News = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    grid-template-columns: 1fr;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const NewFigure = styled.figure`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 240px;
  max-height: 300px;

  a {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1366px) {
    height: 200px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    height: auto;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const NewImage = styled.img`
  width: 100%;
  height: auto;
  transition: 0.9s;
`;

export const NewDate = styled.div`
  position: absolute;
  top: 0;
  right: 19px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-transform: capitalize;
  background-color: ${props => props.theme.colors.boxSecundary};
  padding: 11px 20px;

  color: #ffffff;
  font-family: "NunitoSans-Bold";

  span {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 1.5px;
  }
  small {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.5px;
  }

  @media (max-width: 1366px) {
    span {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1.5px;
    }
    small {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1.5px;
    }
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const NewTitle = styled.h2`
  font-family: "NunitoSans-Bold";
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: ${props => props.theme.colors.text};

  text-align: left;

  padding: 24px;
  // margin-bottom: 87px;

  transition: 0.9s;

  @media (max-width: 1366px) {
    font-size: 16px;
    line-height: 24px;
    padding: 13px;
    // margin-bottom: 100px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    // margin-bottom: 60px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const New = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: ${props => props.theme.colors.backgroundPrimary};

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &:hover {
    ${NewImage} {
      transform: scale(1.1);
    }

    ${NewTitle} {
      color: ${props => props.theme.colors.secundary};
    }
  }
`;

export const NewsFooter = styled(Link)`
  font-family: "NunitoSans-Bold";
  font-size: 20px;
  line-height: 27px;
  color: ${props => props.theme.colors.text};
  text-transform: uppercase;
  text-decoration: underline;

  padding: 24px 0 10px 0;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    font-size: 16px;
    line-height: 23px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Galleries = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    grid-template-columns: 1fr;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const GalleryFigure = styled.figure`
  // position: relative;
  // overflow: hidden;

  // width: 100%;
  // height: 179px;
  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    // height: 250px;
    // max-height: 300px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    grid-template-columns: 1fr;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const GalleryImage = styled.img`
  width: 100%;
  height: auto;
  filter: unset;
  width: 100%;
  height: 179px;
  object-fit: cover;
  object-position: top;

  filter: ${props => (props.theme.title === "dark" ? `grayscale(100%) contrast(120%)` : `unset`)};
`;

export const GalleryTitle = styled.h2`
  // width: 100%;
  // height: 100%;
  // max-height: 0px;

  // padding-left: 15px;

  font-family: "NunitoSans-Bold";
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;

  display: flex;
  // position: absolute;
  bottom: 0;
  text-align: center;
  align-items: center;
  // overflow: hidden;

  background-color: rgba(134, 27, 17, 0.7);

  transition: 0.8s ease-in-out;

  height: 121px;
  padding: 10px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    font-size: 16px;
    line-height: 23px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const GalleryFooter = styled(Link)`
  font-family: "NunitoSans-Bold";
  font-size: 20px;
  line-height: 27px;
  color: ${props => props.theme.colors.text};
  text-transform: uppercase;
  text-decoration: underline;

  padding: 24px 0 10px 0;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    font-size: 16px;
    line-height: 23px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // &:hover {
  //   ${GalleryTitle} {
  //     max-height: 580px;
  //   }
  // }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    // ${GalleryTitle} {
    //   max-height: 50%;
    //   padding: 11px;
    // }
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
