import Axios from "axios";

const INITIAL_STATE = {
  config: {
    business_hours: null,
    address: null,
    phones: null,
    emails: null,
    facebook_page: null,
    instagram_page: null,
    youtube_page: null,
    twitter_page: null,
    linkedin_page: null,
    latitude: 0,
    longitude: 0,
    amount_specialization_certificate: 2,
  },
};

export const updateConfig = config => ({
  type: "SET_CONFIG",
  payload: config,
});

export const getConfig = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateConfig(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CONFIG":
      return { ...state, config: action.payload };
    default:
      return state;
  }
};
