import React, { useState } from "react";

import Pagination from "react-js-pagination";
import { useHistory, Link } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "../../store/banner.reducers";
import { getNews } from "../../store/news.reducers";
import { handlePaginateChange, handleGetPaginate, stripAccents, getDateByTimeZoneCba } from "../../services/functions";

import Header from "../../components/Header";
import Title from "../../components/Title";
import Newsletter from "../../components/Newsletter";
import Footer from "../../components/Footer";
import MainServices from "../../components/MainServices";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemSummary,
  ListItemLink,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterButton,
} from "./styles";

let PATH = "/covid/";

const frontload = async props => {
  await props.getNews(`${process.env.REACT_APP_API}/covid`, {
    headers: { pagenumber: props.match.params.page ?? 1, pagelimit: 10, filter: stripAccents(props.match.params.filter ?? "") },
  });
  return;
};

function Covid(props) {
  if (props.match.params.filter) {
    PATH = `/covid/buscar/${props.match.params.filter}/`;
  }

  let { newsReducers: news } = useSelector(state => state);
  const paginate = news.news;
  news = news.news.data;

  const [filterTerm, setFilterTerm] = useState();
  const history = useHistory();

  function sendSearch() {
    if (filterTerm) {
      history.push(`/covid/buscar/${filterTerm}/`);
    } else {
      history.push(`/covid/`);
    }
  }

  return (
    <>
      <Helmet>
        <title>Fique bem informado com as notícias do CRO-MT {paginate.page ? `- Pág. ${paginate.page}` : ""}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Fique bem informado com as notícias do CRO-MT ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta name="title" content={`Fique bem informado com as notícias do CRO-MT ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Notícias`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Filtrar Notícias da Covid-19</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por título, conteúdo...</FilterLabel>
                    <FilterInput type="text" value={filterTerm} onChange={event => setFilterTerm(event.target.value)} />

                    <FilterButton
                      onClick={() => {
                        sendSearch();
                      }}>
                      Filtrar
                    </FilterButton>
                  </BoxContent>
                </Box>

                <MainServices />
              </Aside>
              <ContentBody>
                {news.map(item => (
                  <ListItem key={item.id}>
                    <Link to={`/noticias/detalhes/${item.uri}`}>
                      <ListItemFigure title={item.title} alt={item.title}>
                        <ListItemImage src={item.image_path} title={item.title} alt={item.title} />
                      </ListItemFigure>
                    </Link>
                    <ListItemContent>
                      <Link to={`/noticias/detalhes/${item.uri}`}>
                        <ListItemTitle>{item.title}</ListItemTitle>
                      </Link>
                      <Link to={`/noticias/detalhes/${item.uri}`}>
                        <ListItemSubTitle>{getDateByTimeZoneCba(item.date, "'De' dd 'de' MMMM 'de' yyyy")}</ListItemSubTitle>
                      </Link>
                      <Link to={`/noticias/detalhes/${item.uri}`}>
                        <ListItemSummary>{item.summary}</ListItemSummary>
                      </Link>

                      <ListItemLink to={`/noticias/detalhes/${item.uri}`}>CLIQUE PARA SABER MAIS</ListItemLink>
                    </ListItemContent>
                  </ListItem>
                ))}

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    news: state.newsReducers.news,
  };
};
const mapDispatchToProps = {
  getBanner,
  getNews,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Covid),
);
