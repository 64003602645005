import styled from "styled-components";

export const Container = styled.div`
  background-color: ${props => props.theme.colors.secundary};
  padding: 0 140px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 9;
  position: fixed;
  width: 100%;
  top: 0;

  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    justify-content: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1366px) {
    padding: 0 25px;
  }
`;

export const Group = styled.div`
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Zoom = styled.button`
  border: 0;
  background: transparent;
  color: #ffffff;
  padding: 12px;
  font-family: "NunitoSans-SemiBold";
  font-size: 14px;

  line-height: 12px;
  cursor: pointer;
`;

export const Shortcut = styled.button`
  border: 0;
  background: transparent;
  color: #ffffff;
  padding: 12px;
  font-family: "NunitoSans-SemiBold";
  font-size: 14px;

  line-height: 12px;
  cursor: pointer;

  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    display: none;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    font-size: 12px;
  }
`;

export const Option = styled.button`
  border: 0;
  background: transparent;
  color: #ffffff;
  padding: 12px;
  font-family: "NunitoSans-SemiBold";
  font-size: 14px;

  line-height: 12px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;

  svg {
    margin-right: 4px;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */

    padding: 5px 12px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }

  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
