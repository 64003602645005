import React from "react";

export function Facebook({ width, height, fillColor }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height={height}
      width={width}
      viewBox="0 0 34.568 34.568">
      <path
        d="M29.912,0H4.66A4.658,4.658,0,0,0,0,4.658V29.91A4.658,4.658,0,0,0,4.66,34.568H17.114l.021-12.353H13.926a.757.757,0,0,1-.757-.754l-.015-3.982a.757.757,0,0,1,.757-.76h3.2V12.872c0-4.465,2.727-6.9,6.71-6.9h3.268a.757.757,0,0,1,.757.757V10.09a.757.757,0,0,1-.757.757H25.087c-2.166,0-2.585,1.029-2.585,2.54v3.331h4.76a.757.757,0,0,1,.752.846l-.472,3.982a.757.757,0,0,1-.752.668H22.523L22.5,34.568h7.41A4.658,4.658,0,0,0,34.57,29.91V4.658A4.658,4.658,0,0,0,29.912,0Z"
        transform="translate(-0.002)"
        fill={fillColor}
      />
    </svg>
  );
}

export function Instagram({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 35.734 35.733">
      <path
        d="M25.088,0H10.645A10.657,10.657,0,0,0,0,10.645V25.088A10.657,10.657,0,0,0,10.645,35.734H25.088A10.657,10.657,0,0,0,35.734,25.088V10.645A10.657,10.657,0,0,0,25.088,0Zm7.05,25.088a7.05,7.05,0,0,1-7.05,7.05H10.645a7.05,7.05,0,0,1-7.05-7.05V10.645a7.05,7.05,0,0,1,7.05-7.05H25.088a7.05,7.05,0,0,1,7.05,7.05V25.088Z"
        fill={fillColor}
      />
      <path
        d="M142.242,133a9.242,9.242,0,1,0,9.242,9.242A9.252,9.252,0,0,0,142.242,133Zm0,14.889a5.647,5.647,0,1,1,5.647-5.647A5.647,5.647,0,0,1,142.242,147.889Z"
        transform="translate(-124.375 -124.375)"
        fill={fillColor}
      />
      <ellipse
        cx="2.215"
        cy="2.215"
        rx="2.215"
        ry="2.215"
        transform="translate(24.912 6.48)"
        fill={fillColor}
      />
    </svg>
  );
}

export function Linkedin({ width, height, fillColor }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height={height}
      width={width}
      viewBox="0 0 310 310"
      style={{ enableBackground: "new 0 0 310 310" }}>
      <g id="XMLID_801_">
        <path
          fill={fillColor}
          id="XMLID_802_"
          d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
		C77.16,101.969,74.922,99.73,72.16,99.73z"
        />
        <path
          fill={fillColor}
          id="XMLID_803_"
          d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
		c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
        />
        <path
          fill={fillColor}
          id="XMLID_804_"
          d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
		c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
		c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
		C310,145.43,300.549,94.761,230.454,94.761z"
        />
      </g>
    </svg>
  );
}

export function Youtube({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 48.851 34.568">
      <path
        d="M38.705,67.393H10.146A10.146,10.146,0,0,0,0,77.539V91.815a10.146,10.146,0,0,0,10.146,10.146H38.705A10.146,10.146,0,0,0,48.851,91.815V77.539A10.146,10.146,0,0,0,38.705,67.393ZM31.844,85.372,18.485,91.743a.536.536,0,0,1-.767-.484V78.118a.536.536,0,0,1,.779-.478l13.358,6.769A.536.536,0,0,1,31.844,85.372Z"
        transform="translate(0 -67.393)"
        fill={fillColor}
      />
    </svg>
  );
}

export function Twitter({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 42.369 34.425">
      <path
        d="M42.369,52.075a18.11,18.11,0,0,1-5,1.372,8.637,8.637,0,0,0,3.821-4.8,17.359,17.359,0,0,1-5.508,2.1,8.685,8.685,0,0,0-15.025,5.94,8.944,8.944,0,0,0,.2,1.981,24.586,24.586,0,0,1-17.9-9.085A8.688,8.688,0,0,0,5.619,61.193a8.578,8.578,0,0,1-3.924-1.07v.1a8.726,8.726,0,0,0,6.959,8.535,8.67,8.67,0,0,1-2.277.286,7.679,7.679,0,0,1-1.644-.148,8.769,8.769,0,0,0,8.116,6.051,17.453,17.453,0,0,1-10.77,3.7A16.267,16.267,0,0,1,0,78.527a24.453,24.453,0,0,0,13.325,3.9c15.984,0,24.722-13.24,24.722-24.717,0-.384-.013-.755-.032-1.123A17.328,17.328,0,0,0,42.369,52.075Z"
        transform="translate(0 -48)"
        fill={fillColor}
      />
    </svg>
  );
}
