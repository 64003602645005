import styled from "styled-components";

export const Container = styled.nav`
  margin-top: 9px;
  transition: all 0.4s ease-in-out;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    position: absolute;
    right: -1500px;
    top: 0;
    width: 70%;
    background: #861810;
    margin-top: -1px;
    height: calc(100vh - 25.5px);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px 1px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const NavItems = styled.ul`
  display: flex;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    flex-direction: column;
    align-items: center;

    flex: 1;
    height: 100%;
    justify-content: space-evenly;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const NavItem = styled.li`
  font-family: "NunitoSans-Regular";
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.colors.title};
  text-transform: uppercase;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;

  cursor: pointer;

  a {
    padding: 15px 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 3px;

      path {
        transition: 0.4s;
      }
    }
  }

  &:hover {
    color: #ffffff;
    background-color: #861811;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }

  @media (max-width: 1366px) {
    font-size: 15px;
    a {
      padding: 14px 16px;
    }
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    font-size: 12px;
    line-height: 21px;

    a {
      padding: 15px 7px;
    }
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    color: #ffffff;
    width: 100%;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      path {
        fill: #ffffff;
      }
    }
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
