import React from 'react'

import logo from "../../assets/logo/footer.jpg";
import step1 from "../../assets/fisc/passo-1.png";
import step2 from "../../assets/fisc/passo-2.png";
import step3 from "../../assets/fisc/passo-3.png";
import step4 from "../../assets/fisc/passo-4.png";
import Footer from '../../components/Footer';

export default function Fisc(){
    return(
        <>
        <div style={{maxWidth: 1000, margin: '0 auto', textAlign: 'center'}}>
            <img src={logo} width="100%" style={{maxWidth: 600}} />
            <div style={{padding: '60px 0'}}>
            <p>
                <b>MANIFESTAÇÃO DE NOTIFICAÇÃO GERADA PELA FISCALIZAÇÃO DO CRO-MT</b>
                <br/><br/>
                <b>Importante:</b> Só deverá ser realizada a manfestação para notificação com prazo em vigor.
                <br/>
                Siga o passo-a-passo abaixo para fazer sua manifestação.
            </p>
            <div style={{paddingTop: 70, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div style={{ width: 'calc(50% - 50px)', minWidth: 300, textAlign: 'left', marginRight: 50, marginBottom: 40}}>
                    <h1 style={{color: '#851811'}}>Passo 1</h1>
                    <br/>
                    <p>Cadastre-se nos "Serviços on-line". Informe seu CPF e clique em "CADASTRAR". Você receberá um e-mail com instruções de acesso. Caso já possui acesso, vá para o 2° passo.</p>
                </div> 
                <div style={{ width: '50%'}}>
                    <img src={step1} width="100%" />
                </div>
            </div>
            <div style={{paddingTop: 70, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div style={{ width: 'calc(50% - 50px)', minWidth: 300, textAlign: 'left', marginRight: 50, marginBottom: 40}}>
                    <h1 style={{color: '#851811'}}>Passo 2</h1>
                    <br/>
                    <p>Faça o login Digite seu cpf e sua senha e clique em "LOGIN"</p>
                </div> 
                <div style={{ width: '50%'}}>
                    <img src={step2} width="100%" />
                </div>
            </div>
            <div style={{paddingTop: 70, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div style={{ width: 'calc(50% - 50px)', minWidth: 300, textAlign: 'left', marginRight: 50, marginBottom: 40}}>
                    <h1 style={{color: '#851811'}}>Passo 3</h1>
                    <br/>
                    <p>Clique em "Requerimentos"</p>
                </div> 
                <div style={{ width: '50%'}}>
                    <img src={step3} width="300px" />
                </div>
            </div>
            <div style={{paddingTop: 70, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <div style={{ width: 'calc(40% - 50px)', minWidth: 300, textAlign: 'left', marginRight: 50, marginBottom: 40}}>
                    <h1 style={{color: '#851811'}}>Passo 4</h1>
                    <br/>
                    <p>Escolha o requerimento "MANIFESTAÇÃO TERMO DE FISCALIZAÇÃO" e prosSiga com as orientações da tela.
                    <br/><br/><b>Importante</b>: Dependendo do dispositivo que você utilizar a disposição (ordem) dos requerimentos podem ser diferentes da imagem abaixo</p>
                </div> 
                <div style={{ width: '60%'}}>
                    <img src={step4} width="100%" />
                </div>
            </div>
            </div>
        </div>
        <Footer/>
        </>
    )
}