import React from "react";

export default function Logout({ width = 20, height = 20, fillColor = "#FFFFFF" }) {
  return (
    <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
      <g>
        <path
          d="M20.25,19.547V23c0,0.552-0.449,1-1,1h-14c-0.551,0-1-0.448-1-1V3c0-0.552,0.449-1,1-1h14
		c0.551,0,1,0.448,1,1v3.453l2,1.733V3c0-1.656-1.344-3-3-3h-14c-1.656,0-3,1.344-3,3v20c0,1.656,1.344,3,3,3h14
		c1.656,0,3-1.344,3-3v-5.187L20.25,19.547z"
          fill={fillColor}
        />
        <g>
          <path
            d="M16.25,19.5c0,0-1,0.281-1-1c0-0.29,0-1.257,0-2.5h-6c-0.552,0-1-0.449-1-1v-4c0-0.551,0.448-1,1-1
			h6c0-1.196,0-2.155,0-2.531c0-1.344,1-0.969,1-0.969l7.5,6.5L16.25,19.5z"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
}
