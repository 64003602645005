import React from "react";

export default function Check({ width, height, fillColor }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 40.925 40.885">
      <g transform="translate(0 -0.5)">
        <path
          d="M40.912,18.587h0a2.349,2.349,0,0,0-.906-1.615l-2.329-1.813.876-2.889a2.339,2.339,0,0,0-1.707-2.957l-2.93-.684-.374-2.986a2.339,2.339,0,0,0-2.762-2.006L27.824,4.2,26.268,1.628a2.364,2.364,0,0,0-3.025-.9,1.392,1.392,0,0,0-.218.125L20.46,2.639,17.99.919a2.339,2.339,0,0,0-3.339.71L13.1,4.2,10.14,3.637A2.339,2.339,0,0,0,7.379,5.644L7.005,8.629l-3.1.723L3.76,9.4l-.054.023A2.341,2.341,0,0,0,2.368,12.27l.875,2.889L.814,17.048l-.141.122-.04.043a2.368,2.368,0,0,0-.076,3.138l1.9,2.274-1.541,2.8-.062.14-.018.052a2.368,2.368,0,0,0,1.218,2.9L4.78,29.8l-.255,3a2.339,2.339,0,0,0,2.284,2.537l3.008.06.987,2.842a2.339,2.339,0,0,0,3.119,1.389L16.7,38.455l2.177,2.323.114.1.044.033a2.4,2.4,0,0,0,1.43.473,2.33,2.33,0,0,0,1.707-.735l2.058-2.2L27,39.624a2.339,2.339,0,0,0,3.118-1.389l.987-2.843,3.182-.063.151-.017.054-.011a2.368,2.368,0,0,0,1.9-2.506l-.255-3,2.724-1.278a2.339,2.339,0,0,0,1.055-3.247l-1.458-2.646,1.9-2.274A2.347,2.347,0,0,0,40.912,18.587ZM36.193,21.6a1.419,1.419,0,0,0-.159,1.6l1.753,3.18L34.5,27.917a1.42,1.42,0,0,0-.808,1.4L34,32.934l-3.631.072a1.419,1.419,0,0,0-1.307.95L27.87,37.387l-3.346-1.41a1.42,1.42,0,0,0-1.581.336l-2.483,2.65-2.483-2.65a1.417,1.417,0,0,0-1.034-.446,1.4,1.4,0,0,0-.546.11l-3.347,1.41-1.191-3.43a1.419,1.419,0,0,0-1.307-.951l-3.631-.072.308-3.618a1.419,1.419,0,0,0-.808-1.4L3.134,26.374l1.753-3.18a1.419,1.419,0,0,0-.16-1.6L2.42,18.839l2.826-2.2a1.418,1.418,0,0,0,.49-1.529L4.683,11.636,8.22,10.81A1.42,1.42,0,0,0,9.3,9.609l.451-3.6,3.567.684a1.42,1.42,0,0,0,1.476-.657l1.878-3.108L19.652,5a1.418,1.418,0,0,0,1.616,0l2.98-2.075,1.878,3.107A1.419,1.419,0,0,0,27.6,6.69l3.566-.684.451,3.6a1.419,1.419,0,0,0,1.081,1.2l3.536.826-1.053,3.475a1.419,1.419,0,0,0,.49,1.53l2.825,2.2Zm0,0"
          transform="translate(0)"
          fill={fillColor}
        />
        <path
          d="M140.257,128.781a10.191,10.191,0,1,0,10.191,10.191A10.2,10.2,0,0,0,140.257,128.781Zm0,17.982a7.791,7.791,0,1,1,7.791-7.791A7.8,7.8,0,0,1,140.257,146.763Zm0,0"
          transform="translate(-119.67 -118.028)"
          fill={fillColor}
        />
        <path
          d="M201.36,212.645l-4.734,4.733-1.285-1.285a1.2,1.2,0,1,0-1.7,1.7l2.133,2.133a1.2,1.2,0,0,0,1.7,0l5.582-5.582a1.2,1.2,0,1,0-1.7-1.7Zm0,0"
          transform="translate(-177.844 -194.865)"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}
