import React from "react";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getMagazine } from "./../../store/magazine.reducers";
import { handlePaginateChange, handleGetPaginate, getDate } from "./../../services/functions";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemLink,
} from "./styles";

const PATH = "/revista/";

const frontload = async props => {
  await props.getMagazine(`${process.env.REACT_APP_API}/magazines`, {
    headers: { pagenumber: props.match.params.page ?? 1, pagelimit: 10 },
  });
  return;
};

function Magazines() {
  let { magazineReducers: magazines } = useSelector(state => state);
  const paginate = magazines.magazine;
  magazines = magazines.magazine.data;

  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>
          Confira as edições da Revista CRO-MT - {process.env.REACT_APP_TITLE} {paginate.page ? `- Pág. ${paginate.page}` : ""}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta
          property="og:title"
          content={`Confira as edições da Revista CRO-MT - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`}
        />
        <meta
          name="title"
          content={`Confira as edições da Revista CRO-MT - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`}
        />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Revista CRO-MT`} />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>
              <ContentBody>
                {magazines.map(item => (
                  <ListItem key={item.id}>
                    {item.image_path ? (
                      <a href={item.file_path} title={`Baixar - ${item.title}`} alt={`Baixar - ${item.title}`} target="_blank">
                        <ListItemFigure title={item.title} alt={item.title}>
                          <ListItemImage src={item.image_path} title={item.title} alt={item.title} />
                        </ListItemFigure>
                      </a>
                    ) : (
                      ``
                    )}

                    <ListItemContent>
                      <a href={item.file_path} title={`Baixar - ${item.title}`} alt={`Baixar - ${item.title}`} target="_blank">
                        <ListItemTitle>{item.title}</ListItemTitle>
                      </a>
                      <a href={item.file_path} title={`Baixar - ${item.title}`} alt={`Baixar - ${item.title}`} target="_blank">
                        <ListItemSubTitle>{getDate(item.created_at, "dd 'de' MMMM 'de' yyyy")}</ListItemSubTitle>
                      </a>
                      <ListItemLink href={item.file_path} title={`Baixar - ${item.title}`} alt={`Baixar - ${item.title}`} target="_blank">
                        CLIQUE PARA BAIXAR
                      </ListItemLink>
                    </ListItemContent>
                  </ListItem>
                ))}

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    magazine: state.magazineReducers.magazine,
  };
};
const mapDispatchToProps = {
  getBanner,
  getMagazine,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Magazines),
);
