import Axios from "axios";

const INITIAL_STATE = {
  news: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        id: null,
        title: null,
        summary: null,
        content: null,
        image_path: null,
        date: null,
        is_active: null,
        uri: null,
      },
    ],
  },
};

export const updateNews = (news) => ({
  type: "SET_NEWS",
  payload: news,
});

export const getNews = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then((resp) => {
      dispatch(updateNews(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_NEWS":
      return { ...state, news: action.payload };
    default:
      return state;
  }
};
