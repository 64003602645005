import styled from "styled-components";
import { Link } from "react-router-dom";

export const Main = styled.main`
  position: relative;
  margin-top: 170px;

  @media (max-width: 1366px) {
    margin-top: 152px;
  }
  @media (max-width: 1281px) {
    margin-top: 230px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 209px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    margin-top: 200px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    margin-top: 110px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Container = styled.section`
  padding: 40px 140px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;

  @media (max-width: 1366px) {
    padding: 40px 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex: 1;
`;

export const Aside = styled.aside`
  align-self: flex-start;

  width: 408px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    width: 90%;
    align-self: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentMain = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentBody = styled.div`
  flex: 1;
  margin-left: 90px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 1366px) {
    margin-left: 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-left: 0px;
    width: 100%;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContentBox = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
  border-radius: 5px;
`;

export const FilterLabel = styled.label`
  color: #3c3535;
  font-size: 20px;
  line-height: 27px;
  font-family: "NunitoSans-Bold";

  margin-bottom: 20px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 8px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const FilterInput = styled.input`
  color: #3c3535;
  font-size: 18px;
  line-height: 27px;
  font-family: "NunitoSans-Light";

  border: 0;
  border-bottom: 0.5px solid #e6e6e6;

  height: 30px;
`;

export const FilterSelect = styled.select`
  color: #3c3535;
  font-size: 18px;
  line-height: 27px;
  font-family: "NunitoSans-Light";

  border: 0;
  border-bottom: 0.5px solid #e6e6e6;
  height: 40px;
  background: white;
`;

export const FilterButton = styled.button`
  font-size: 21px;
  letter-spacing: 2px;
  line-height: 28px;
  font-family: "NunitoSans-Regular";
  text-transform: uppercase;
  color: #ffffff;

  border: 0;
  border-radius: 5px;

  background-color: #861811;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  padding: 13px 43px;
  margin-top: 60px;

  cursor: pointer;
  transition: 0.5s;

  &:hover {
    border-radius: 20px;
    opacity: 0.7;
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 23px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxTitle = styled.div`
  background-color: #eaeaea;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #3c3535;
  font-family: "NunitoSans-ExtraBold";
  font-size: 19px;
  line-height: 35px;
  text-transform: uppercase;

  padding: 22px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 30px;
    padding: 2px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 45px;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    padding: 13px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Box = styled.div`
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  margin-bottom: 50px;

  width: 100%;
`;

export const AsideButton = styled(Link)`
  color: #ffffff;
  font-size: 21px;
  letter-spacing: 2px;
  line-height: 28px;
  font-family: "NunitoSans-Light";
  text-transform: uppercase;

  border: 0;
  border-radius: 5px;

  background-color: #861811;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  padding: 13px 43px;

  margin-bottom: 50px;

  cursor: pointer;
  transition: 0.5s;

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 15px;
  }
`;

export const Form = styled.form`
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const GroupInput = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 17px;
  padding-bottom: 7px;

  transition: box-shadow 0.4s ease-in;

  border-radius: 5px;

  &.active {
    background-color: #f7f0f0;
  }
`;

export const InputLabel = styled.label`
  font-family: "NunitoSans-Light";
  font-size: 14px;
  line-height: 22px;
  color: ${props => (props.required ? "#D9473D" : "#9B9B9B")};
  text-transform: uppercase;

  @media (max-width: 350px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

export const Input = styled.input`
  font-family: "NunitoSans-Regular";
  font-size: 18px;
  line-height: 24px;
  color: #3c3535;

  border: 0;
  padding: 8px 0;

  background-color: transparent;

  border-bottom: 0.5px solid #e6e6e6;
  border-radius: 5px;

  text-transform: capitalize;

  &:-internal-autofill-selected {
    background-color: transparent;
  }
`;

export const Textarea = styled.textarea`
  font-family: "NunitoSans-Regular";
  font-size: 18px;
  line-height: 24px;
  color: #3c3535;

  border: 0;
  padding: 8px 0;

  background-color: transparent;

  border-bottom: 0.5px solid #e6e6e6;
  border-radius: 5px;

  height: 150px;
  resize: none;

  &:-internal-autofill-selected {
    background-color: transparent;
  }
`;

export const Select = styled.select`
  font-family: "NunitoSans-Regular";
  font-size: 18px;
  line-height: 24px;
  color: #3c3535;

  border: 0;
  padding: 8px 0;

  background-color: transparent;

  border-bottom: 0.5px solid #e6e6e6;
  border-radius: 5px;

  height: 40px;

  &:-internal-autofill-selected {
    background-color: transparent;
  }
`;

export const BorderInput = styled.div`
  position: absolute;

  width: 5px;
  height: 86px;

  background-color: #d9473d;

  left: -5px;
  top: 0px;

  z-index: 6;
  transition: top 0.2s ease-in;

  @media (max-width: 350px) {
    width: 5px;
    left: -2.5px;
  }
`;

export const FormButton = styled.button`
  color: #ffffff;
  font-size: 21px;
  letter-spacing: 2px;
  line-height: 28px;
  font-family: "NunitoSans-Regular";
  text-transform: uppercase;

  border: 0;
  border-radius: 5px;

  background-color: #861811;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  padding: 13px 43px;
  margin-top: 60px;

  cursor: pointer;
  transition: 0.5s;

  &:hover {
    border-radius: 20px;
    opacity: 0.7;
  }
`;

export const Thumbnail = styled.label`
  border: 0.5px dashed #e6e6e6;

  background-color: #fff;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 0px;

  cursor: pointer;

  min-height: 250px;

  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & input {
    display: none;
  }
  &.has-thumbnail {
    border: 1px dashed #ddd;
    &.has-thumbnail img {
      display: none;
    }
  }
`;

export const ThumbnailIcon = styled.img`
  width: 30px;
`;

export const ThumbnailInput = styled.input``;
