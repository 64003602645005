import React from "react";

export default function Contrast({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16">
      <path
        d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm.469,15.047V.953a7.063,7.063,0,0,1,0,14.095Z"
        fill={fillColor}
      />
    </svg>
  );
}
