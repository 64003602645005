import Axios from "axios";

const INITIAL_STATE = {
  service: [
    {
      id: null,
      name: null,
      title: null,
      image_path: null,
      position: null,
      uri: "",
      target: null,
      is_active: null,
    },
  ],
};

export const updateService = (service) => ({
  type: "SET_SERVICE",
  payload: service,
});

export const getService = (url, service) => (dispatch, getState) => {
  return Axios.get(url, service)
    .then((resp) => {
      dispatch(updateService(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SERVICE":
      return { ...state, service: action.payload };
    default:
      return state;
  }
};
