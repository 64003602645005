import React from "react";

export default function Cromt({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45.504 17.277">
      <path
        d="M174.74,272.49c-3,0-3.443-.836-2.2-5.967,1.467-6.062,5.775-5.684,5.775-2.366,0,2.34-1.383,8.333-3.574,8.333Zm7.165-9.681c2.246,3.359-.37,9.083-5.56,10.525-4.2,1.168-10.238.365-10.376-4.59-.053-1.907.96-4.385,2.27-5.16v1.724h2.6v-.862H169.51c.1-1.871-1.51-2.421-3.233-1.69a3.566,3.566,0,0,0-1.211.893,5.477,5.477,0,0,0-1.565,2.63l-1.436,7.072a12.843,12.843,0,0,0,8.343,3.772,13.5,13.5,0,0,0,14.407-13.252h2.6c0,7.684-7.308,13.51-15.816,13.793h-1.343a18.337,18.337,0,0,1-12.71-5.8l1.916-9.716c-2.623.192-2.408.91-2.924,2.873h-1.625c0-1.643-.031-3.219-2.607-3.234a4.286,4.286,0,0,0-3.636,2.026c-.432.727-3.256,7.262-.029,8.2l.779.2a3.539,3.539,0,0,0,4.191-2.877h2.6c-.956,1.6-1.508,2.687-3.374,3.627a10.749,10.749,0,0,1-5.744.718c-3.056-.355-4.85-1.776-5.182-4.079,0-2.852.346-4.858,2.834-7.036,3.589-3.141,9.485-1.833,12.763-1.848,2.384-.011,4.769,0,7.153,0,0,.384-.1,1.417-.1,1.8a7.584,7.584,0,0,1,8.1-1.55c3.17-1.151,7.626-.583,9.251,1.848Z"
        transform="translate(-141.91 -260.388)"
        fill={fillColor}
        fill-rule="evenodd"
      />
    </svg>
  );
}
