import React from "react";
import { Link } from "react-router-dom";

import { Container, Figure, Image, Footer } from "./styles";

export default function Magazine({ image, title, uri }) {
  return (
    <>
      <Container>
        <a href={uri} alt={`Baixar - ${title}`} title={`Baixar - ${title}`} target="_blank">
          <Figure>
            <Image src={image} title={title} alt={title} />
          </Figure>
        </a>
      </Container>
      <Footer to="/revistas">Ver todas edições</Footer>
    </>
  );
}
