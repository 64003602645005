import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

/* import store */
import { getBanner } from "./../../../store/banner.reducers";

/* import services */
import api from "./../../../services/api";
import { login, isAuthenticated } from "./../../../services/auth";

/* import functions */
import { maskInteger, maskChaveSeguranca, maskCep, maskTelephone89Digitos, maskCpfCnpj } from "./../../../services/functions";

/* import components */
import Header from "./../../../components/Header";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import MainServices from "./../../../components/MainServices";

/* import styles */
import { Main, Container, Content, Aside, ContentBody, ContentMain, ContentBox, Form, GroupInput, InputLabel, Input, BorderInput, FormButton } from "./styles";

const frontload = async props => {
  return;
};

function Auth() {
  const recaptchaRef = React.useRef();

  const [activeInput, setActiveInput] = useState("cpf");
  const [borderInputPosition, setBorderInputPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const [cpf, setCpf] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  async function setFocus(index) {
    await setActiveInput(index);
    handleScrollForm();
  }

  function handleScrollForm() {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`.active`)) {
        const offset = global.window.document.querySelector(`.active`).offsetTop;
        setBorderInputPosition(offset - 18);
      }
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    if (password !== passwordConfirm) {
      alert("Por favor confirme a sua nova senha corretamente.");
      return false;
    }

    setLoading(true);
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      onResolvedRecaptcha();
    } else {
      alert("Erro! Tente novamente");
      console.log("erro recaptcha: ", token);
      setLoading(false);
    }
  };

  const onResolvedRecaptcha = async () => {
    try {
      const { data } = await api.post(`/site_users/forgot`, { cpf });

      alert(data);
      setLoading(false);
    } catch (error) {
      console.log("erro", error.response);

      if (error.response.status && error.response.status === 404) {
        alert("Desculpe! Não encontramos usuário com o dados informado\nFale conosco em caso de dúvidas.");
      } else {
        alert("Erro ao tentar redefinir a sua senha\nTente novamente");
      }

      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Recupere sua conta para se inscrever em nossos cursos - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Recupere sua conta para se inscrever em nossos cursos - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Recupere sua conta para se inscrever em nossos cursos - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title="Recupere sua conta" />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <Form autoComplete="off" onSubmit={handleSave}>
                    <BorderInput style={{ top: borderInputPosition }}></BorderInput>

                    <GroupInput className={activeInput === "cpf" ? `active` : ""}>
                      <InputLabel for="cpf" required>
                        CPF *
                      </InputLabel>
                      <Input
                        id="cpf"
                        type="text"
                        required
                        onFocus={() => setFocus("cpf")}
                        value={cpf}
                        onChange={event => setCpf(maskCpfCnpj(event.target))}
                        onKeyUp={event => setCpf(maskCpfCnpj(event.target))}
                      />
                    </GroupInput>

                    {/* <GroupInput className={activeInput === "email" ? `active` : ""}>
                      <InputLabel for="email" required>
                        E-mail *
                      </InputLabel>
                      <Input
                        id="email"
                        type="email"
                        required
                        onFocus={() => setFocus("email")}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        style={{ textTransform: "none" }}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "password" ? `active` : ""}>
                      <InputLabel for="password" required>
                        Nova Senha *
                      </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        onFocus={() => setFocus("password")}
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                        required
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "passwordConfirm" ? `active` : ""}>
                      <InputLabel for="passwordConfirm" required>
                        Confirme sua nova Senha *
                      </InputLabel>
                      <Input
                        id="passwordConfirm"
                        type="password"
                        onFocus={() => setFocus("passwordConfirm")}
                        value={passwordConfirm}
                        onChange={event => setPasswordConfirm(event.target.value)}
                        required
                      />
                    </GroupInput> */}

                    <FormButton disabled={loading ? true : false}>Redefinir a senha</FormButton>

                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" />
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Auth),
);
