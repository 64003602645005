import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

/* import store */
import { getBanner } from "./../../../store/banner.reducers";

/* import services */
import api from "./../../../services/api";
import upload from "./../../../services/s3";

/* import utils */
import { createPreviewImage, compress } from "./../../../util/thumbnail";

/* import functions */
import { maskTelephone89Digitos, maskInteger } from "./../../../services/functions";

/* import components */
import Header from "./../../../components/Header";
import Featured from "./../../../components/Featured";
import Title from "./../../../components/Title";
import Newsletter from "./../../../components/Newsletter";
import Footer from "./../../../components/Footer";
import MainServices from "./../../../components/MainServices";

/* import icons */
import inbox from "./../../../assets/icons/inbox.svg";

/* import styles */
import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  BoxLists,
  BoxList,
  BoxItem,
  ContentBody,
  ContentMain,
  ContentBox,
  Form,
  GroupInput,
  InputLabel,
  Input,
  Textarea,
  Select,
  BorderInput,
  FormButton,
  Thumbnail,
  ThumbnailIcon,
  ThumbnailInput,
  CheckingBoxes,
} from "./styles";

const frontload = async props => {
  return;
};

function Create() {
  const recaptchaRef = React.useRef();

  const [activeInput, setActiveInput] = useState("ads_type_id");
  const [borderInputPosition, setBorderInputPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const [types, setTypes] = useState([]);

  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [cro, setCro] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [ads_type_id, setAds_type_id] = useState("");
  const [termAccept, setTermAccept] = useState(false);

  const [image_path, setImage_path] = useState("");
  const [compressedImages, setCompressedImages] = useState([]);

  async function setFocus(index) {
    await setActiveInput(index);
    handleScrollForm();
  }

  function handleScrollForm() {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`.active`)) {
        const offset = global.window.document.querySelector(`.active`).offsetTop;
        setBorderInputPosition(offset - 18);
      }
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    if (!termAccept) {
      alert("Após ler o regulamento!\nMarque a opção li e concordo com os termos.");
      return false;
    }

    setLoading(true);

    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      onResolvedRecaptcha();
    } else {
      alert("Erro! Tente novamente");
      console.log("erro recaptcha: ", token);
      setLoading(false);
    }
  };

  const onResolvedRecaptcha = async () => {
    const client_id = process.env.REACT_APP_CLIENT_ID;
    const site_id = process.env.REACT_APP_SITE_ID;

    let image = "";
    if (compressedImages[0].blob !== null || compressedImages[0].file !== "") {
      const images = await upload(client_id, site_id, compressedImages);

      if (images && images[0].fileUrl) {
        image = images[0].fileUrl;
      } else {
        image = "";
      }
    }

    try {
      await api.post("/ads", {
        title,
        name,
        cro,
        tel,
        email,
        description,
        ads_type_id,
        image_path: image,
      });

      setLoading(false);

      alert(
        "Recebemos sua solicitação para divulgar esse anúncio no site, breve o nosso setor responsável vai validar as informações e divulgar em nosso site.",
      );

      document.location.reload();
    } catch (error) {
      console.log(error);
      alert("Erro ao tentar enviar anúncio. Tente novamente!");
      setLoading(false);
    }
  };

  /* effect load segments  */
  useEffect(() => {
    async function loadTypes() {
      const { data } = await api.get("/ads/types");
      setTypes(data);
    }
    loadTypes();
  }, []);

  useEffect(() => {
    async function compressImage() {
      setCompressedImages(await compress([image_path]));
    }
    compressImage();
  }, [image_path]);

  return (
    <>
      <Helmet>
        <title>Anuncie - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Anuncie - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Anuncie - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title="Anuncie no CRO-MT" />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Classificados</BoxTitle>
                  <BoxContent>
                    <BoxLists>
                      <BoxList>
                        {types.map(({ id, name, uri }) => (
                          <Link to={`/classificados/?tipo=${uri}`} key={id}>
                            <BoxItem>{name}</BoxItem>
                          </Link>
                        ))}
                      </BoxList>
                    </BoxLists>
                  </BoxContent>
                </Box>

                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <Form autoComplete="off" onSubmit={handleSave}>
                    <BorderInput style={{ top: borderInputPosition }}></BorderInput>

                    <GroupInput className={activeInput === "ads_type_id" ? `active` : ""}>
                      <InputLabel for="ads_type_id" required>
                        Tipo de anúncio
                      </InputLabel>
                      <Select
                        id="ads_type_id"
                        value={ads_type_id}
                        required
                        onFocus={() => setFocus("ads_type_id")}
                        onChange={event => setAds_type_id(event.target.value)}>
                        <option value="">Selecione</option>
                        {types.map(type => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Select>
                    </GroupInput>

                    <GroupInput className={activeInput === "title" ? `active` : ""}>
                      <InputLabel for="title" required>
                        Título do anúncio
                      </InputLabel>
                      <Input
                        id="title"
                        maxLength={250}
                        type="text"
                        value={title}
                        required
                        onFocus={() => setFocus("title")}
                        onChange={event => setTitle(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "name" ? `active` : ""}>
                      <InputLabel for="name" required>
                        Seu Nome
                      </InputLabel>
                      <Input id="name" type="text" value={name} required onFocus={() => setFocus("name")} onChange={event => setName(event.target.value)} />
                    </GroupInput>

                    <GroupInput className={activeInput === "tel" ? `active` : ""}>
                      <InputLabel for="tel" required>
                        Telefone
                      </InputLabel>
                      <Input
                        id="tel"
                        value={tel}
                        required
                        type="text"
                        onFocus={() => setFocus("tel")}
                        onChange={event => setTel(event.target.value)}
                        onKeyUp={event => setTel(maskTelephone89Digitos(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "cro" ? `active` : ""}>
                      <InputLabel for="cro">N° CRO-MT</InputLabel>
                      <Input
                        id="cro"
                        type="text"
                        onFocus={() => setFocus("cro")}
                        value={cro}
                        onChange={event => setCro(event.target.value)}
                        onKeyUp={event => setCro(maskInteger(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "email" ? `active` : ""}>
                      <InputLabel for="email">E-mail</InputLabel>
                      <Input
                        id="email"
                        type="email"
                        onFocus={() => setFocus("email")}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        style={{ textTransform: "none" }}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "description" ? `active` : ""}>
                      <InputLabel for="description" required>
                        Descrição
                      </InputLabel>
                      <Textarea
                        id="description"
                        required
                        type="text"
                        onFocus={() => setFocus("description")}
                        value={description}
                        onChange={event => setDescription(event.target.value)}></Textarea>
                    </GroupInput>

                    <GroupInput>
                      <InputLabel for="benefits">CLIQUE PARA ANEXAR UMA FOTO DO ANÚNCIO</InputLabel>
                      <Thumbnail
                        style={{
                          backgroundImage: `url("${createPreviewImage(image_path)}")`,
                        }}>
                        <ThumbnailInput id="" accept="image/x-png,image/gif,image/jpeg" type="file" onChange={event => setImage_path(event.target.files[0])} />
                        <ThumbnailIcon style={{ display: image_path ? "none" : "" }} src={inbox} alt="Selecione uma imagem" />
                      </Thumbnail>
                    </GroupInput>

                    <CheckingBoxes>
                      <input type="checkbox" id="termAccept" checked={termAccept} onChange={() => setTermAccept(!termAccept)} />
                      <label for="termAccept">
                        <Link to="/paginas/regulamento-cadastro-de-anuncios" target="_blank">
                          Li e concordo com o regulamento descrito aqui (ler regulamento)
                        </Link>
                      </label>
                    </CheckingBoxes>

                    <FormButton disabled={loading ? true : false}>{loading ? "Cadastrando Anúncio ..." : "Cadastrar Anúncio"}</FormButton>
                    {/*<Recaptcha ref={ref => (recaptcha = ref)} sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" onResolved={onResolvedRecaptcha} />*/}
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" />
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Create),
);
