import React from "react";

export default function Triangle({ width, height, fillColor, style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      style={style}
      viewBox="0 0 6 5">
      <path
        d="M3,0,6,5H0Z"
        transform="translate(6 5) rotate(180)"
        fill={fillColor}
      />
    </svg>
  );
}
