import React, { useState } from "react";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

/* import store */
import { getBanner } from "./../../store/banner.reducers";

/* import services */
import api from "./../../services/api";

/* import functions */
import { maskTelephone89Digitos, maskCep, maskInteger, maskCpfCnpj } from "./../../services/functions";

/* import components */
import Header from "./../../components/Header";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

/* import styles */
import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ContentBox,
  Form,
  GroupInput,
  InputLabel,
  Input,
  Select,
  BorderInput,
  FormButton,
  CheckingBoxes,
  FormTitle,
} from "./styles";

const frontload = async props => {
  return;
};

const categories = [
  { initials: "CD", name: "Cirurgião(ã)-Dentista" },
  { initials: "ASB", name: "Auxiliar em Saúde Bucal" },
  { initials: "TSB", name: "Técnico em Saúde Bucal" },
  { initials: "LB", name: "Laboratório" },
  { initials: "TPD", name: "Técnico(a) em Prótese Dentária" },
  { initials: "EPAO", name: "Entidade Prestadora de Assistência Odontológica" },
  { initials: "APD", name: "Auxiliar de Prótese Dentária" },
  { initials: "EPO", name: "Empresa de Produtos Odontológica" },
];

function Create() {
  const recaptchaRef = React.useRef();

  const [activeInput, setActiveInput] = useState("categories");
  const [borderInputPosition, setBorderInputPosition] = useState(0);
  const [loading, setLoading] = useState(false);

  const [category, setCategory] = useState("");
  const [category_name, setCategory_name] = useState("");
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [tel, setTel] = useState("");
  const [cell, setCell] = useState("");
  const [date_birth, setDate_birth] = useState("");
  const [cro, setCro] = useState("");
  const [home_address, setHome_address] = useState("");
  const [home_address_number, setHome_address_number] = useState("");
  const [home_address_cep, setHome_address_cep] = useState("");
  const [home_address_city, setHome_address_city] = useState("");
  const [home_address_state, setHome_address_state] = useState("");
  const [home_address_neighborhood, setHome_address_neighborhood] = useState("");
  const [home_address_tel, setHome_address_tel] = useState("");
  const [home_address_email, setHome_address_email] = useState("");

  const [business_in_home, setBusiness_in_home] = useState("unset");

  const [business_address, setBusiness_address] = useState("");
  const [business_address_number, setBusiness_address_number] = useState("");
  const [business_address_cep, setBusiness_address_cep] = useState("");
  const [business_address_city, setBusiness_address_city] = useState("");
  const [business_address_state, setBusiness_address_state] = useState("");
  const [business_address_neighborhood, setBusiness_address_neighborhood] = useState("");
  const [business_address_tel, setBusiness_address_tel] = useState("");
  const [business_address_email, setBusiness_address_email] = useState("");

  const [mailing_address, setMailing_address] = useState("home");

  async function setFocus(index) {
    await setActiveInput(index);
    handleScrollForm();
  }

  function handleScrollForm() {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`.active`)) {
        const offset = global.window.document.querySelector(`.active`).offsetTop;
        setBorderInputPosition(offset - 18);
      }
    }
  }

  const handleSave = async event => {
    event.preventDefault();

    setLoading(true);
    //onResolvedRecaptcha();
    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      onResolvedRecaptcha();
    } else {
      alert("Erro! Tente novamente");
      console.log("erro recaptcha: ", token);
      setLoading(false);
    }
  };

  const onResolvedRecaptcha = async () => {
    const data = {
      name,
      cpf,
      tel,
      cell,
      date_birth,
      cro,
      home_address,
      home_address_number,
      home_address_cep,
      home_address_city,
      home_address_state,
      home_address_neighborhood,
      home_address_tel,
      home_address_email,
      business_in_home,
      business_address,
      business_address_number,
      business_address_cep,
      business_address_city,
      business_address_state,
      business_address_neighborhood,
      business_address_tel,
      business_address_email,
      mailing_address,
      category,
      category_name,
    };
    try {
      await api.post("/registrations", data);

      setLoading(false);
      alert("As informações foram enviadas com sucesso.");
      document.location.reload();
    } catch (error) {
      console.log(error);
      alert("Erro ao tentar enviar as informações. Tente novamente!");
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Atualize seu cadastro - {process.env.REACT_APP_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Atualize seu cadastro - ${process.env.REACT_APP_TITLE}`} />
        <meta name="title" content={`Atualize seu cadastro - ${process.env.REACT_APP_TITLE}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title="Atualização cadastral" />
            <ContentMain>
              <Aside>
                <MainServices />
              </Aside>

              <ContentBody>
                <ContentBox>
                  <Form autoComplete="off" onSubmit={handleSave}>
                    <BorderInput style={{ top: borderInputPosition }}></BorderInput>

                    <GroupInput className={activeInput === "categories" ? `active` : ""}>
                      <InputLabel for="categories" required>
                        Categoria *
                      </InputLabel>
                      <Select
                        id="categories"
                        value={category}
                        required
                        onFocus={() => setFocus("categories")}
                        onChange={event => {
                          setCategory(event.target.value);
                          setCategory_name(event.target.options[event.target.selectedIndex].text);
                        }}>
                        <option value="">Selecione</option>
                        {categories.map(category => (
                          <option key={category.initials} value={category.initials}>
                            {category.name}
                          </option>
                        ))}
                      </Select>
                    </GroupInput>

                    <GroupInput className={activeInput === "cro" ? `active` : ""}>
                      <InputLabel for="cro" required>
                        Nº de inscrição no CRO *
                      </InputLabel>
                      <Input
                        id="cro"
                        required
                        type="text"
                        onFocus={() => setFocus("cro")}
                        value={cro}
                        onChange={event => setCro(event.target.value)}
                        onKeyUp={event => setCro(maskInteger(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "name" ? `active` : ""}>
                      <InputLabel for="name" required>
                        Nome completo *
                      </InputLabel>
                      <Input id="name" type="text" value={name} required onFocus={() => setFocus("name")} onChange={event => setName(event.target.value)} />
                    </GroupInput>

                    <GroupInput className={activeInput === "cpf" ? `active` : ""}>
                      <InputLabel for="cpf" required>
                        CPF/CNPJ *
                      </InputLabel>
                      <Input
                        id="cpf"
                        type="text"
                        required
                        onFocus={() => setFocus("cpf")}
                        value={cpf}
                        onChange={event => setCpf(event.target.value)}
                        onKeyUp={event => setCpf(maskCpfCnpj(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "tel" ? `active` : ""}>
                      <InputLabel for="tel">Telefone Fixo</InputLabel>
                      <Input
                        id="tel"
                        type="text"
                        onFocus={() => setFocus("tel")}
                        value={tel}
                        onChange={event => setTel(event.target.value)}
                        onKeyUp={event => setTel(maskTelephone89Digitos(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "cell" ? `active` : ""}>
                      <InputLabel for="cell" required>
                        Celular *
                      </InputLabel>
                      <Input
                        id="cell"
                        type="text"
                        onFocus={() => setFocus("cell")}
                        value={cell}
                        required
                        onChange={event => setCell(event.target.value)}
                        onKeyUp={event => setCell(maskTelephone89Digitos(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "date_birth" ? `active` : ""}>
                      <InputLabel for="date_birth" required>
                        Data de nascimento *
                      </InputLabel>
                      <Input
                        id="date_birth"
                        required
                        type="date"
                        onFocus={() => setFocus("date_birth")}
                        value={date_birth}
                        onChange={event => setDate_birth(event.target.value)}
                        style={{ textTransform: "none" }}
                      />
                    </GroupInput>

                    <GroupInput style={{ margin: "25px 0px", borderBottom: "1px solid #3C3535", borderRadius: 0, padding: "0" }}>
                      <FormTitle>Endereço residencial</FormTitle>
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address_cep" ? `active` : ""}>
                      <InputLabel for="home_address_cep">CEP</InputLabel>
                      <Input
                        id="home_address_cep"
                        type="text"
                        onFocus={() => setFocus("home_address_cep")}
                        value={home_address_cep}
                        onChange={event => setHome_address_cep(event.target.value)}
                        onKeyUp={event => setHome_address_cep(maskCep(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address" ? `active` : ""}>
                      <InputLabel for="home_address" required>
                        Endereço *
                      </InputLabel>
                      <Input
                        id="home_address"
                        type="text"
                        required
                        onFocus={() => setFocus("home_address")}
                        value={home_address}
                        onChange={event => setHome_address(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address_number" ? `active` : ""}>
                      <InputLabel for="home_address_number">Número</InputLabel>
                      <Input
                        id="home_address_number"
                        type="text"
                        onFocus={() => setFocus("home_address_number")}
                        value={home_address_number}
                        onChange={event => setHome_address_number(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address_neighborhood" ? `active` : ""}>
                      <InputLabel for="home_address_neighborhood" required>
                        Bairro *
                      </InputLabel>
                      <Input
                        id="home_address_neighborhood"
                        required
                        type="text"
                        onFocus={() => setFocus("home_address_neighborhood")}
                        value={home_address_neighborhood}
                        onChange={event => setHome_address_neighborhood(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address_city" ? `active` : ""}>
                      <InputLabel for="home_address_city" required>
                        Cidade *
                      </InputLabel>
                      <Input
                        id="home_address_city"
                        required
                        type="text"
                        onFocus={() => setFocus("home_address_city")}
                        value={home_address_city}
                        onChange={event => setHome_address_city(event.target.value)}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address_state" ? `active` : ""}>
                      <InputLabel for="home_address_state" required>
                        Estado (UF) *
                      </InputLabel>
                      <Input
                        id="home_address_state"
                        required
                        type="text"
                        maxLength={2}
                        onFocus={() => setFocus("home_address_state")}
                        value={home_address_state}
                        onChange={event => setHome_address_state(event.target.value)}
                        style={{ textTransform: "uppercase" }}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address_tel" ? `active` : ""}>
                      <InputLabel for="home_address_tel">Telefone</InputLabel>
                      <Input
                        id="home_address_tel"
                        type="text"
                        onFocus={() => setFocus("home_address_telll")}
                        value={home_address_tel}
                        onChange={event => setHome_address_tel(event.target.value)}
                        onKeyUp={event => setHome_address_tel(maskTelephone89Digitos(event.target))}
                      />
                    </GroupInput>

                    <GroupInput className={activeInput === "home_address_email" ? `active` : ""}>
                      <InputLabel for="home_address_email" required>
                        E-mail *
                      </InputLabel>
                      <Input
                        id="home_address_email"
                        type="email"
                        required
                        onFocus={() => setFocus("home_address_email")}
                        value={home_address_email}
                        onChange={event => setHome_address_email(event.target.value)}
                        style={{ textTransform: "none" }}
                      />
                    </GroupInput>

                    <CheckingBoxes>
                      <label for="termAccept">Endereço comercial é o mesmo que o Residencial?</label>
                      <div>
                        <input
                          type="radio"
                          required
                          id="business_in_home_true"
                          name="business_in_home"
                          checked={business_in_home && business_in_home !== "unset"}
                          onChange={() => setBusiness_in_home(true)}
                        />
                        <label for="business_in_home_true" checked>
                          Sim
                        </label>

                        <input
                          type="radio"
                          required
                          id="business_in_home_false"
                          name="business_in_home"
                          checked={!business_in_home && business_in_home !== "unset"}
                          onChange={() => setBusiness_in_home(false)}
                        />
                        <label for="business_in_home_false">Não</label>
                      </div>
                    </CheckingBoxes>

                    <div style={{ display: business_in_home === false ? "block" : "none" }}>
                      <GroupInput style={{ margin: "25px 0px", borderBottom: "1px solid #3C3535", borderRadius: 0, padding: "0" }}>
                        <FormTitle>Endereço comercial</FormTitle>
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address_cep" ? `active` : ""}>
                        <InputLabel for="business_address_cep">CEP</InputLabel>
                        <Input
                          id="business_address_cep"
                          type="text"
                          onFocus={() => setFocus("business_address_cep")}
                          value={business_address_cep}
                          onChange={event => setBusiness_address_cep(event.target.value)}
                          onKeyUp={event => setBusiness_address_cep(maskCep(event.target))}
                        />
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address" ? `active` : ""}>
                        <InputLabel for="business_address">Endereço</InputLabel>
                        <Input
                          id="business_address"
                          type="text"
                          onFocus={() => setFocus("business_address")}
                          value={business_address}
                          onChange={event => setBusiness_address(event.target.value)}
                        />
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address_number" ? `active` : ""}>
                        <InputLabel for="business_address_number">Número</InputLabel>
                        <Input
                          id="business_address_number"
                          type="text"
                          onFocus={() => setFocus("business_address_number")}
                          value={business_address_number}
                          onChange={event => setBusiness_address_number(event.target.value)}
                        />
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address_neighborhood" ? `active` : ""}>
                        <InputLabel for="business_address_neighborhood">Bairro</InputLabel>
                        <Input
                          id="business_address_neighborhood"
                          type="text"
                          onFocus={() => setFocus("business_address_neighborhood")}
                          value={business_address_neighborhood}
                          onChange={event => setBusiness_address_neighborhood(event.target.value)}
                        />
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address_city" ? `active` : ""}>
                        <InputLabel for="business_address_city">Cidade</InputLabel>
                        <Input
                          id="business_address_city"
                          type="text"
                          onFocus={() => setFocus("business_address_city")}
                          value={business_address_city}
                          onChange={event => setBusiness_address_city(event.target.value)}
                        />
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address_state" ? `active` : ""}>
                        <InputLabel for="business_address_state">Estado (UF)</InputLabel>
                        <Input
                          id="business_address_state"
                          type="text"
                          maxLength={2}
                          onFocus={() => setFocus("business_address_state")}
                          value={business_address_state}
                          onChange={event => setBusiness_address_state(event.target.value)}
                          style={{ textTransform: "uppercase" }}
                        />
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address_tel" ? `active` : ""}>
                        <InputLabel for="business_address_tel">Telefone</InputLabel>
                        <Input
                          id="business_address_tel"
                          type="text"
                          onFocus={() => setFocus("business_address_telll")}
                          value={business_address_tel}
                          onChange={event => setBusiness_address_tel(event.target.value)}
                          onKeyUp={event => setBusiness_address_tel(maskTelephone89Digitos(event.target))}
                        />
                      </GroupInput>

                      <GroupInput className={activeInput === "business_address_email" ? `active` : ""}>
                        <InputLabel for="business_address_email">E-mail </InputLabel>
                        <Input
                          id="business_address_email"
                          type="email"
                          onFocus={() => setFocus("business_address_email")}
                          value={business_address_email}
                          onChange={event => setBusiness_address_email(event.target.value)}
                          style={{ textTransform: "none" }}
                        />
                      </GroupInput>

                      <CheckingBoxes>
                        <label for="termAccept">Endereço de entrega?</label>
                        <div>
                          <input
                            type="radio"
                            id="mailing_address_home"
                            name="mailing_address"
                            checked={mailing_address === "home" ? true : false}
                            onChange={() => setMailing_address("home")}
                          />
                          <label for="mailing_address_home">Residencial</label>

                          <input
                            type="radio"
                            id="mailing_address_business"
                            name="mailing_address"
                            checked={mailing_address === "business" ? true : false}
                            onChange={() => setMailing_address("business")}
                          />
                          <label for="mailing_address_business" checked>
                            Comercial
                          </label>
                        </div>
                      </CheckingBoxes>
                    </div>

                    <FormButton disabled={loading ? true : false}>{loading ? "Enviando ... " : "Enviar"}</FormButton>

                    {/*<Recaptcha ref={ref => (recaptcha = ref)} sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" onResolved={onResolvedRecaptcha} />*/}
                    <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey="6Le4SfoUAAAAAIPw67Jqbl9xSaIcUsL4e-P2oQ2c" />
                  </Form>
                </ContentBox>
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
  };
};
const mapDispatchToProps = {
  getBanner,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Create),
);
