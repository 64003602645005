import React from "react";

export default function Left({ width, height, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 14.687 25">
      <g transform="translate(-101.48)" opacity="0.898">
        <g transform="translate(101.48 0)">
          <path
            d="M106.415,12.505l9.352-9.353a1.371,1.371,0,0,0,0-1.934L114.948.4a1.37,1.37,0,0,0-1.934,0L101.879,11.535a1.38,1.38,0,0,0,0,1.941L113,24.6a1.37,1.37,0,0,0,1.934,0l.819-.819a1.369,1.369,0,0,0,0-1.934Z"
            transform="translate(-101.48 0)"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  );
}
