import Axios from "axios";

const INITIAL_STATE = {
  courses: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        name: null,
        summary: null,
        local: null,
        address: null,
        instructors: null,
        curriculum: null,
        start_date: null,
        end_date: null,
        total_hours: null,
        capacity: null,
        certificate_path: null,
        image_path: null,
        uri: null,
        is_active: null,
        is_virtual: null,
      },
    ],
  },
};

export const updateCourses = data => ({
  type: "SET_COURSES",
  payload: data,
});

export const getCourses = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateCourses(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_COURSES":
      return { ...state, courses: action.payload };
    default:
      return state;
  }
};
