import styled from "styled-components";

export const Container = styled.header`
  background-color: ${props => props.theme.colors.backgroundPrimary};
  padding: 30px 140px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  position: fixed;
  top: 39.5px;
  z-index: 8;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px 1px;

  transition: all 0.4s ease-in-out;

  @media (max-width: 1366px) {
    padding: 15px 25px 0;

    .logo {
      width: 320px !important;
    }
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    top: 25.5px;
    padding: 7px 0px 0;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    justify-content: center;
    .logo {
      width: 220px !important;
    }
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const ContainerGroup = styled.section`
  margin-left: 23px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

export const Group = styled.div`
  display: flex;
  align-self: flex-end;

  transition: 0.4s ease-in-out;

  .menuIcon {
    display: none;
  }

  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .menuIcon {
      display: block;
    }
    #nav {
      right: ${props => (props.openedMenu ? "0px " : "-1500px ")} !important;
    }
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */

    .hide-1025 {
      display: none;
      width: 0px;
      overflow: hidden;
    }
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1366px) {
    align-self: center;
  }
`;

export const GroupItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 17px;
  position: relative;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    display: none;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Label = styled.label`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: ${props => props.theme.colors.text};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1366px) {
  }
`;

export const ImageTransparencia = styled.img`
  height: auto;
  width: 87px;
`;
