import styled from "styled-components";

export const Container = styled.section`
  max-height: 75vh;
  height: min-content;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 170px;

  @media (max-width: 1366px) {
    margin-top: 152px;
  }
  @media (max-width: 1281px) {
    margin-top: 230px;
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    margin-top: 209px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    margin-top: 200px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    margin-top: 110px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Figure = styled.figure`
  display: none;
  height: 100%;
  width: 100%;
  overflow: hidden;

  transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;

  a {
    line-height: 0;
  }
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
`;

export const Button = styled.button`
  background-color: transparent;
  border: 0;
  position: absolute;
  z-index: 7;

  left: ${props => (props.right ? `auto` : `40px`)};
  right: ${props => (props.right ? `40px` : `auto`)};

  cursor: pointer;
  transition: 0.4s;

  &:hover {
    path {
      fill: #861811;
    }
  }

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    svg {
      height: 25px;
    }
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    left: ${props => (props.right ? `auto` : `20px`)};
    right: ${props => (props.right ? `20px` : `auto`)};
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
