import Axios from "axios";
import { useHistory } from "react-router-dom";

const INITIAL_STATE = {
  videoDetails: {
    id: null,
    title: null,
    video_path: null,
    is_active: null,
    uri: null,
    date: null,
  },
};

export const updateVideoDetails = param => ({
  type: "SET_VIDEO_DETAILS",
  payload: param,
});

export const getVideoDetails = (url, config, history) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateVideoDetails(resp.data));
    })
    .catch(function (error) {
      history.push("/404");
    });
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_VIDEO_DETAILS":
      return { ...state, videoDetails: action.payload };
    default:
      return state;
  }
};
