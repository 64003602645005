import Axios from "axios";

const INITIAL_STATE = {
  partners: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        name: null,
        address: null,
        address_number: null,
        address_cep: null,
        address_city: null,
        address_state: null,
        address_neighborhood: null,
        phones: null,
        site: null,
        image_path: null,
        benefits: null,
        is_active: null,
        partner_segment_id: null,
        segment: { name: "" },
      },
    ],
  },
};

export const updatePartners = param => ({
  type: "SET_PARTNERS",
  payload: param,
});

export const getPartners = (url, config) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updatePartners(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_PARTNERS":
      return { ...state, partners: action.payload };
    default:
      return state;
  }
};
