import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getPartners } from "./../../store/partners.reducers";
import { handlePaginateChange, handleGetPaginate, stripAccents } from "./../../services/functions";
import api from "./../../services/api";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import globe from "./../../assets/icons/globe.svg";
import phone2 from "./../../assets/icons/phone2.svg";
import map2 from "./../../assets/icons/map2.svg";

import { createUri } from "./../../util/createUri";

import {
  Main,
  Container,
  Content,
  Aside,
  Box,
  BoxTitle,
  BoxContent,
  FilterLabel,
  FilterInput,
  FilterSelect,
  FilterButton,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemSummary,
  ListItemLine,
  ContentBody2,
} from "./styles";

let PATH = "/parceiros/";

const frontload = async props => {
  await props.getPartners(`${process.env.REACT_APP_API}/partners`, {
    headers: {
      pagenumber: props.match.params.page ?? 1,
      pagelimit: 12,
      term: stripAccents(props.match.params.filter ?? ""),
      segment: stripAccents(props.match.params.segment),
    },
  });
  return;
};

function Partners(props) {
  if (props.match.params.filter || props.match.params.segment) {
    PATH = `/parceiros/buscar/${props.match.params.filter}/segmento/${props.match.params.segment}/`;
  }

  let { partnersReducers: partners } = useSelector(state => state);
  const paginate = partners.partners;
  partners = partners.partners.data;

  const [filterTerm, setFilterTerm] = useState();
  const [filterSegment, setFilterSegment] = useState();
  const [segments, setSegments] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function loadSegments() {
      const { data } = await api.get("/partners/segments");
      setSegments(data);
    }
    loadSegments();
  }, []);

  function sendSearch() {
    if (filterTerm || filterSegment) {
      history.push(`/parceiros/buscar/${filterTerm ?? "-"}/segmento/${filterSegment ?? "-"}/`);
    } else {
      history.push(`/parceiros/`);
    }
  }

  return (
    <>
      <Helmet>
        <title>Conheça nossos parceiros e aproveite dos benefícios {paginate.page ? `- Pág. ${paginate.page}` : ""}</title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Conheça nossos parceiros e aproveite dos benefícios ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta name="title" content={`Conheça nossos parceiros e aproveite dos benefícios ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="https://cromt.org.br/logo512.png" />
        <meta property="og:site_name" content="CRO-MT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Parceiros`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Filtro de Parceiros</BoxTitle>
                  <BoxContent>
                    <FilterLabel>Busque por nome, endereço, benefícios…</FilterLabel>
                    <FilterInput type="text" value={filterTerm} onChange={event => setFilterTerm(event.target.value)} />
                    <FilterLabel>Segmento</FilterLabel>
                    <FilterSelect type="text" value={filterSegment} onChange={event => setFilterSegment(event.target.value)}>
                      <option value=""></option>
                      {segments.map(segment => (
                        <option key={segment.id} value={segment.id}>
                          {segment.name}
                        </option>
                      ))}
                    </FilterSelect>
                    <FilterButton
                      onClick={() => {
                        sendSearch();
                      }}>
                      Pesquisar
                    </FilterButton>
                  </BoxContent>
                </Box>

                <MainServices />
              </Aside>
              <ContentBody>
                <ContentBody2>
                  {partners.map(partner => (
                    <ListItem to={`/parceiros/${partner.id}/${createUri(partner.name)}`}>
                      <ListItemImage src={partner.image_path} title={partner.name} alt={partner.name} />
                      <ListItemContent>
                        <ListItemTitle>{partner.name}</ListItemTitle>
                        <ListItemSummary>{partner?.benefits}</ListItemSummary>
                      </ListItemContent>
                    </ListItem>
                  ))}
                </ContentBody2>

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    partners: state.partnersReducers.partners,
  };
};
const mapDispatchToProps = {
  getBanner,
  getPartners,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Partners),
);
