import styled from "styled-components";

export const Container = styled.section`
  background-color: ${props => props.theme.colors.newsletter};
  padding: 40px 140px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 280px;
  align-items: center;

  @media (max-width: 1366px) {
    padding: 40px 25px;
    grid-gap: 200px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    grid-gap: 100px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    grid-gap: 50px;
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Title = styled.h2`
  font-family: "NunitoSans-Bold";
  font-size: 40px;
  letter-spacing: 1.2px;
  line-height: 54px;
  color: ${props => props.theme.colors.title};

  @media (max-width: 1366px) {
    font-size: 32px;
    line-height: 46px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 22px;
    line-height: 36px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    text-align: center;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Form = styled.form`
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  font-family: "NunitoSans-Regular";
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;

  width: 100%;
  background-color: #861811;
  border-radius: 7px;
  border: 0;
  padding: 14.5px 20px;

  &::placeholder {
    opacity: 1;
    font-family: "NunitoSans-Regular";
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-family: "NunitoSans-Regular";
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    font-family: "NunitoSans-Regular";
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 1px;
    color: #ffffff;
    text-transform: uppercase;
  }

  @media (max-width: 1366px) {
    font-size: 21px;
    line-height: 29px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "NunitoSans-Regular";
  font-size: 21px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;

  border: 0;
  background-color: #861811;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  margin-top: 30px;
  padding: 16px 73px;
  cursor: pointer;

  transition: 0.5s;

  &:hover {
    border-radius: 20px;
    opacity: 0.7;
  }

  @media (max-width: 1366px) {
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    font-size: 16px;
    line-height: 21px;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    margin-top: 10px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;
