export const createUri = text => {
  if (!text) return;

  return text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/([^\w]+|\s+)/g, "-")
    .replace(/\-\-+/g, "-")
    .replace(/(^-+|-+$)/, "");
};
