import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { frontloadConnect } from "react-frontload";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import { getBanner } from "./../../store/banner.reducers";
import { getAds } from "./../../store/ads.reducers";
import { handlePaginateChange, handleGetPaginate, stripAccents } from "./../../services/functions";

import api from "./../../services/api";

import Header from "./../../components/Header";
import Featured from "./../../components/Featured";
import Title from "./../../components/Title";
import Newsletter from "./../../components/Newsletter";
import Footer from "./../../components/Footer";
import MainServices from "./../../components/MainServices";

import Speaker from "./../../assets/icons/speaker";

import {
  Main,
  Container,
  Content,
  Aside,
  ContentBody,
  ContentMain,
  ListItem,
  ListItemFigure,
  ListItemImage,
  ListItemContent,
  ListItemTitle,
  ListItemSubTitle,
  ListItemSummary,
  ListItemLink,
  Box,
  BoxTitle,
  BoxContent,
  BoxLists,
  BoxList,
  BoxItem,
  AsideButton,
} from "./styles";

let PATH = "/classificados/";

const frontload = async props => {
  await props.getAds(`${process.env.REACT_APP_API}/ads`, {
    headers: { pagenumber: props.match.params.page ?? 1, pagelimit: 10, type: props.match.params.category ?? "" },
  });
  return;
};

function Ads(props) {
  if (props.match.params.filter) {
    PATH = `/classificados/categoria/${props.match.params.category}/`;
  }

  let { adsReducers: ads } = useSelector(state => state);
  const paginate = ads.ads;
  ads = ads.ads.data;

  const [types, setTypes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function loadTypes() {
      const { data } = await api.get("/ads/types");
      setTypes(data);
    }
    loadTypes();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Anúncios - {process.env.REACT_APP_TITLE} {paginate.page ? `- Pág. ${paginate.page}` : ""}
        </title>
        <meta name="description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:title" content={`Anuncios - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta name="title" content={`Anuncios - ${process.env.REACT_APP_TITLE} ${paginate.page ? `- Pág. ${paginate.page}` : ""}`} />
        <meta property="og:description" content={process.env.REACT_APP_DESCRIPTION} />
        <meta property="og:image" content="/logo512.png" />
        <meta property="og:site_name" content="CROMT" />
        <meta property="og:url" content={global.window ? global.window.location : ""} />
      </Helmet>
      <Header />

      <Main id="main">
        <Container>
          <Content>
            <Title title={`Classificados`} />
            <ContentMain>
              <Aside>
                <Box>
                  <BoxTitle>Categorias</BoxTitle>
                  <BoxContent>
                    <BoxLists>
                      <BoxList>
                        {types.map(({ id, name, uri }) => (
                          <Link to={`/classificados/categoria/${uri}`} key={id}>
                            <BoxItem>{name}</BoxItem>
                          </Link>
                        ))}
                      </BoxList>
                    </BoxLists>
                  </BoxContent>
                </Box>

                <AsideButton to="/anuncie-no-cromt">
                  <Speaker width={35.96} height={40.88} fillColor="#FFFFFF" />
                  CADASTRAR ANÚNCIO
                </AsideButton>

                <MainServices />
              </Aside>
              <ContentBody>
                {ads.map(ad => (
                  <ListItem key={ad.id}>
                    {ad.image_path ? (
                      <Link to={`/classificados/detalhes/${ad.uri}`}>
                        <ListItemFigure title={ad.title} alt={ad.title}>
                          <ListItemImage src={ad.image_path} title={ad.title} alt={ad.title} />
                        </ListItemFigure>
                      </Link>
                    ) : (
                      ``
                    )}

                    <ListItemContent>
                      <Link to={`/classificados/detalhes/${ad.uri}`}>
                        <ListItemTitle>{ad.title}</ListItemTitle>
                      </Link>
                      <Link to={`/classificados/detalhes/${ad.uri}`}>
                        <ListItemSubTitle>{ad.type.name}</ListItemSubTitle>
                      </Link>
                      <Link to={`/classificados/detalhes/${ad.uri}`}>
                        <ListItemSummary
                          dangerouslySetInnerHTML={{
                            __html: ad.description ?? "",
                          }}></ListItemSummary>
                      </Link>

                      <ListItemLink to={`/classificados/detalhes/${ad.uri}`}>CLIQUE PARA SABER MAIS</ListItemLink>
                    </ListItemContent>
                  </ListItem>
                ))}

                <Pagination
                  activePage={paginate.page}
                  itemClass="pageItem"
                  itemsCountPerPage={paginate.perPage}
                  totalItemsCount={paginate.total}
                  pageRangeDisplayed={5}
                  onChange={pageNumber => handlePaginateChange(PATH, pageNumber, history)}
                  getPageUrl={i => {
                    return handleGetPaginate(PATH, i);
                  }}
                />
              </ContentBody>
            </ContentMain>
          </Content>
        </Container>
        <Newsletter />
      </Main>
      <Footer />
    </>
  );
}

// export default Home
const mapStateToProps = state => {
  return {
    banner: state.bannerReducers.banner,
    ads: state.adsReducers.ads,
  };
};
const mapDispatchToProps = {
  getBanner,
  getAds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false,
  })(Ads),
);
