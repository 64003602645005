import Axios from "axios";
import { useHistory } from "react-router-dom";

const INITIAL_STATE = {
  scheduleDetails: {},
};

export const updateScheduleDetails = param => ({
  type: "SET_SCHEDULE_DETAILS",
  payload: param,
});

export const getScheduleDetails = (url, config, history) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateScheduleDetails(resp.data));
    })
    .catch(function (error) {
      history.push("/404");
    });
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SCHEDULE_DETAILS":
      return { ...state, scheduleDetails: action.payload };
    default:
      return state;
  }
};
