import Axios from "axios";

const INITIAL_STATE = {
  magazine: {
    total: 0,
    perPage: 0,
    page: 0,
    lastPage: 0,
    data: [
      {
        id: null,
        title: null,
        file_path: null,
        image_path: null,
        is_active: null,
      },
    ],
  },
};

export const updateMagazine = magazine => ({
  type: "SET_MAGAZINE",
  payload: magazine,
});

export const getMagazine = (url, magazine) => (dispatch, getState) => {
  return Axios.get(url, magazine)
    .then(resp => {
      dispatch(updateMagazine(resp.data));
    })
    .catch(function (error) {});
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_MAGAZINE":
      return { ...state, magazine: action.payload };
    default:
      return state;
  }
};
