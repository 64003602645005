import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "./css/image-gallery.css";

export default function MyGallery({ images }) {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    let img = [];
    img = images.map(image => {
      return { original: image.image_path, thumbnail: image.image_path, thumbnailTitle: "Teste" };
    });

    setGallery(img);
  }, []);

  return (
    <ImageGallery
      showFullscreenButton={false}
      useBrowserFullscreen={false}
      showPlayButton={false}
      showBullets={false}
      autoPlay
      items={gallery}
    />
  );
}
