import React, { useState } from "react";
import { Link } from "react-router-dom";

import Contrast from "./../../assets/icons/contrast";
//import Sitemap from "./../../assets/icons/sitemap";
import Accessibility from "./../../assets/icons/accessibility";

import { Container, Group, Zoom, Shortcut, Option } from "./styles";
import { useEffect } from "react";

import GlobalStyle from "./../../styles/global";

export default function Head({ toggleFontSize }) {
  const [contrast, setContrast] = useState(false);

  function handleAnchor(to) {
    if (typeof global.window !== "undefined") {
      if (global.window.document.querySelector(`#${to}`)) {
        const offset = global.window.document.querySelector(`#${to}`).offsetTop;
        window.scrollTo({ top: offset, left: 0, behavior: "smooth" });
        if (to === "search") {
          global.window.document.querySelector(`#${to}`).focus();
        }
      }
    }
  }

  function handleKeyPress() {
    if (typeof global.window !== "undefined") {
      global.document.addEventListener("keypress", event => {
        if (event.altKey) {
          if (event.code === "Digit1" || event.key === "¹") handleAnchor("main");

          if (event.code === "Digit2" || event.key === "²") handleAnchor("nav");

          //if (event.code === "Digit3" || event.key === "³") handleAnchor("search");

          if (event.code === "Digit3" || event.key === "£") handleAnchor("footer");
        }
      });
    }
    return "";
  }

  useEffect(() => {
    handleKeyPress();
  }, []);

  return (
    <>
      <GlobalStyle contrast={contrast} />

      <Container id="head">
        {/*<Group>
        <Zoom>A+</Zoom>
        <Zoom>A-</Zoom>
      </Group>*/}
        <Group>
          <Shortcut onClick={() => handleAnchor("main")}>Ir para conteúdo [ALT + 1]</Shortcut>
          <Shortcut onClick={() => handleAnchor("nav")}>Ir para o menu [ALT + 2]</Shortcut>
          {/*<Shortcut onClick={() => handleAnchor("search")}>Ir para a busca [ALT + 3]</Shortcut>*/}
          <Shortcut onClick={() => handleAnchor("footer")}>Ir para o rodapé [ALT + 3]</Shortcut>
        </Group>
        <Group>
          <Option onClick={() => setContrast(!contrast)}>
            <Contrast width={16} height={16} fillColor={"#FFFFFF"} /> Contraste
          </Option>
          {/*<Link to="/paginas/sitemap">
          <Option>
            <Sitemap width={18.79} height={16.35} fillColor={"#FFFFFF"} /> Mapa do site
          </Option>
  </Link>*/}
          <Link to="/paginas/acessibilidade">
            <Option>
              <Accessibility width={18.79} height={16.35} fillColor={"#FFFFFF"} /> Acessibilidade
            </Option>
          </Link>
        </Group>
      </Container>
    </>
  );
}
