import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";

function createCSS(dates) {
  let styles = "";

  dates.map(
    ({ date, count, color }, index) =>
      (styles += `abbr[aria-label="${date}"]  {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        &::after {
          content: '${count === 1 ? "." : count === 2 ? ".." : count >= 3 ? "..." : ""}';
          line-height: 0px;
          letter-spacing: 2px;
        }
      };
      `),
  );

  return css`
    ${styles}
  `;
}

export const Container = styled.aside`
  width: 408px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    display: none;
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const CalendarFooter = styled(Link)`
  font-family: "NunitoSans-Bold";
  font-size: 18px;
  line-height: 25px;
  color: #3c3535;
  text-transform: uppercase;
  text-decoration: underline;

  padding: 24px 0 10px 0;

  @media (max-width: 1366px) {
  }
  @media (max-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  @media (max-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (max-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    font-size: 18px;
    line-height: 25px;
  }
  @media (max-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    font-size: 16px;
    line-height: 23px;
  }
  @media (max-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
  }
  @media (max-width: 320px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  }
`;

export const CalendarElement = styled(Calendar)`
  .react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: 0;
    padding: 0 !important;
  }
  .react-calendar--doubleView {
    width: 500px;
  }
  .react-calendar__navigation__label {
    flex: 1;
  }

  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    height: 79px;
    padding: 0 !important;
    display: flex;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background-color: #eaeaea;
    color: #3c3535;
    text-transform: uppercase;
    font-family: "NunitoSans-ExtraBold";
    font-size: 19px;
    line-height: 35px;
    border: 0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 13px;
    color: #3c3535;
    font-family: "NunitoSans-Bold";
    font-size: 14px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000 !important;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #eaeaea !important;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
  }
  .react-calendar__tile {
    text-align: center;
    flex-basis: unset !important;
    max-width: unset !important;
    width: 48.2px;
    height: 52px;
    background-color: #eaeaea;
    border-right: 1px solid #eaeaea !important;
    color: #3c3535;
    font-family: "NunitoSans-Bold";
    font-size: 17px;
    line-height: 27px;
    margin: 5px !important;
    border: 0;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__tile--now {
    background: #a2cc9c;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #a2cc9c;
  }
  .react-calendar__tile--hasActive {
    background: #76baff;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
  }
  .react-calendar__tile--active {
    background: #579d4d;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #579d4d;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__year-view__months,
  .react-calendar__century-view__decades,
  .react-calendar__decade-view__years {
    justify-content: center;
  }

  .react-calendar__year-view__months__month,
  .react-calendar__century-view__decades__decade,
  .react-calendar__decade-view__years__year {
    width: 45% !important;
  }

  ${props => createCSS(props.dates)}
`;
