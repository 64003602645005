import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Title, Content, Lists, List, Item } from "./styles";
import api from "../../services/api";

function MainServices() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    async function loadServices() {
      await api.get(`${process.env.REACT_APP_API}/services`).then(({ data }) => {
        setServices(data);
      });
    }

    loadServices();
  }, []);

  return (
    <Container>
      <Title>Serviços</Title>
      <Content>
        <Lists>
          <List>
            {services.map(({ id, name, title, target, uri }) => {
              if (uri.substr(0, 4) === "http") {
                return (
                  <a href={uri} title={title} alt={title} target={target} key={id} rel="noopener noreferrer">
                    <Item>{name}</Item>
                  </a>
                );
              } else {
                return (
                  <Link to={uri} key={id}>
                    <Item>{name}</Item>
                  </Link>
                );
              }
            })}
          </List>
        </Lists>
      </Content>
    </Container>
  );
}

export default MainServices;
