import Axios from "axios";
import { useHistory } from "react-router-dom";

const INITIAL_STATE = {
  courseDetails: {},
};

export const updateCourseDetails = param => ({
  type: "SET_COURSE_DETAILS",
  payload: param,
});

export const getCourseDetails = (url, config, history) => (dispatch, getState) => {
  return Axios.get(url, config)
    .then(resp => {
      dispatch(updateCourseDetails(resp.data));
    })
    .catch(function (error) {
      history.push("/404");
    });
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_COURSE_DETAILS":
      return { ...state, courseDetails: action.payload };
    default:
      return state;
  }
};
